import firebase from './firebaseApp.js';
import {fbNowDate} from '../utils/utils';

const databaseRef = () => firebase.firestore().collection('appInfo/');

export const getInfo = async (field) => {
  const docs = await databaseRef().get();
  try {
    const info = docs.docs[0].data();
    return info[field];
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getMobileFormInfo = async () => {
  const docs = await databaseRef().get();
  const info = docs.docs[0].data();
  return {languages: info.languages, payments: info.payments};
};

export const getLastIndex = async () => getInfo('lastIndex');

export const incrementLastIndex = async (value) =>
  databaseRef().doc('DfaI6LMVUGfjUu29yYKj').update({lastIndex: value});
export const updateImportDate = async (value) =>
  databaseRef().doc('DfaI6LMVUGfjUu29yYKj').update({lastImportedDate: fbNowDate()});
