import React, {useCallback, useContext} from 'react';
import FormContext from '../FormContext';
import StepLayout from '../StepLayout';
import styles from '../MobileForm.module.css';
import {useForm} from 'react-hook-form';
import {emailPattern, usPhonePatter} from '../../../utils/pattern';
import InputMask from 'react-input-mask';

const ConfirmForm = () => {
  const {formData, setFormData} = useContext(FormContext);
  const {
    register,
    errors,
    formState: {isValid, touched},
    trigger,
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: false,
  });

  const updateFormDataField = useCallback(
    (e) => {
      const {name, value} = e.target;
      setFormData((formData) => ({...formData, [name]: value}));
      trigger().then();
    },
    [setFormData, trigger]
  );

  return (
    <StepLayout blocked={!isValid}>
      <h4 className={styles.stepTitle}>It's almost done ;)</h4>
      <div>
        <label className={styles.sectionLabel}>Location owner</label>
        <input
          name="ownerName"
          value={formData.ownerName}
          onChange={updateFormDataField}
          className={`${styles.textField} ${
            errors.ownerName && touched.ownerName && 'form-control is-invalid'
          }`}
          placeholder="Full name"
          ref={register({
            required: true,
          })}
        />
        <InputMask
          mask="+1\ 999 999 9999"
          maskChar=" "
          name="ownerPhone"
          onChange={updateFormDataField}
          value={formData.ownerPhone}
        >
          {(inputProps) => (
            <input
              {...inputProps}
              type="tel"
              name="ownerPhone"
              className={`${styles.textField} ${
                errors.ownerPhone && touched.ownerPhone && 'form-control is-invalid'
              }`}
              placeholder="Contact phone"
              ref={register({
                required: true,
                pattern: usPhonePatter,
              })}
            />
          )}
        </InputMask>
        <input
          name="ownerEmail"
          value={formData.ownerEmail}
          onChange={updateFormDataField}
          className={`${styles.textField} ${
            errors.ownerEmail && touched.ownerEmail && 'form-control is-invalid'
          }`}
          placeholder="Email address"
          ref={register({
            required: true,
            pattern: emailPattern,
          })}
        />
        <textarea
          name="ownerDetails"
          value={formData.ownerDetails}
          onChange={updateFormDataField}
          className={styles.textField}
          style={{height: '140px'}}
          placeholder="Other details (optional)"
        />
      </div>
    </StepLayout>
  );
};

export default ConfirmForm;
