import React from 'react'
import Navbar from '../components/navbar/Navbar.js'

const withNavBar = (WrappedComponent) => props => {

    return(
        <div className="">
            <Navbar/>
            <WrappedComponent {...props} />
        </div>
    )
}

export default withNavBar