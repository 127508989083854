import React, {memo} from 'react';
import {useItemSelector} from '../../hooks';

const ItemSelector = ({items, multi = false, selected: preselected, onSelect, children}) => {
  const [selected, setSelected] = useItemSelector(
    items,
    multi ? preselected : [preselected],
    multi
  );
  const select = (i) => {
    if (!multi && i === selected[0]) return;
    setSelected(i, onSelect);
  };

  return items.map((t, i) => (
    <div key={i} onClick={() => select(i)}>
      {children(t, selected.includes(i))}
    </div>
  ));
};

export default memo(ItemSelector);
