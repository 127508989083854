import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import MultiLevelSelect from './multilevel/index.js';
import {getLevels} from '../../firebase/servicesApi.js';
import {FETCH_GROUPS} from '../../redux/types.js';
import {Loader} from '../loader/Loader';

const DropdownMultilevel = ({info, value, handler}) => {
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.services.groups);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchGroups = async () => {
      setIsLoading(true);
      const data = await getLevels();
      dispatch({type: FETCH_GROUPS, payload: data});
      setIsLoading(false);
    };
    if (groups.length === 0) fetchGroups();
  }, [groups, dispatch]);

  if (isLoading) return <Loader />;
  return (
    <MultiLevelSelect
      options={groups}
      onChange={(e) => handler(info.field, transformForHandler(e))}
      selected={transformSelected(groups, value)}
    />
  );
};

const getLevelDataById = (allLevelsData, levelId) => {
  let levelData = null;
  allLevelsData.forEach((item) => {
    if (item.id === levelId) {
      levelData = item;
    } else {
      item?.services.length &&
        item.services.forEach((itemService) => {
          if (itemService.id === levelId) {
            levelData = itemService;
          }
        });
    }
  });
  return levelData;
};

const transformSelected = (groups = [], selected = []) => {
  if (groups.length === 0 || selected.length === 0) return [];
  const resultData = {};
  for (const service of selected) {
    resultData[service.GroupID] = {};
    resultData[service.GroupID].values = [];
  }

  for (const s of selected) {
    const selectedData = getLevelDataById(groups, s.ID);
    const obj = {
      Availability: s.Availability,
      GroupID: s.GroupID,
      value: s.ID,
      label: selectedData ? selectedData.Label : getLabel(s.ID),
    };
    resultData[s.GroupID].values.push(obj);
  }
  const final = [];
  for (const service in resultData) {
    final.push({value: service, label: getLabel(service), options: resultData[service].values});
  }
  return final;
};

const transformForHandler = (selected) => {
  const resultData = [];
  for (const group of selected) {
    for (const service of group.options) {
      const data = {};
      data.Availability = service.Availability ? service.Availability : 'Busy';
      data.GroupID = group.value;
      data.ID = service.value.trim();
      resultData.push(data);
    }
  }
  return resultData;
};

const getLabel = (label) => label.replace(/_/g, ' ');

export default DropdownMultilevel;
