import React from 'react'
import { connect } from 'react-redux'
import OfferCard from '../../components/offerCard/OfferCard'
import Button from '../../components/button/button'
import Table from '../../components/table/Table.js'
import FilterPanel from '../../components/filters/FilterPanel.js'
import withNavBar from '../../hoc/withNavBar.js'
import { offersTable, offersFiltes } from '../../mapper.js'
import { fetchOffers, addNewOffer, updateExistingOffer, deleteExistingOffer } from '../../redux/companiesActions'
import { checkOffersPrevState, composeOffersFilters } from '../../components/filters/filterUtilFunctions.js'

class Offers extends React.Component {

    state = {
        dateFilter: null,
        typeFilter: 'All',
        isOpen: false,
        selected: null,
    }


    async componentDidMount() {
        const { getOffers, isFetching } = this.props
        if (!isFetching) getOffers(this.state)
    }

    componentDidUpdate( _, prevState) {
        if(checkOffersPrevState(prevState, this.state)) {
            const { getOffers } = this.props
            getOffers(composeOffersFilters(this.state))
        }
    }

    handleFilterSelection = (field, value) => this.setState({ [field]: value })

    editAction = (id) => {
        const { offers } = this.props
        const index = offers.findIndex(offer => offer.id === id)
        if(index > -1){
            this.setState({ isOpen:true, selected: offers[index] })
        }
    }
    deleteAction = (id) => {
        const { offers } = this.props
        const index = offers.findIndex(offer => offer.id === id)
        if(index > -1){
            this.deleteOffer(offers[index])
        }
    }
    handleOffer = (action, offer) => {
        this.setState({ isOpen:false })
        switch (action) {
            case 'create': this.createOffer(offer); return;
            case 'update': this.updateOffer(offer); return;
            case 'close': this.selected = null; return;
            default: return
        }
    }


    createOffer = offer => {
        this.setState({ selected: null })
        this.props.addOffer(offer)
    }
    updateOffer = offer => {
        this.setState({ selected: null })
        this.props.updateOffer(offer)
    }
    deleteOffer = offer => {
        this.props.deleteOffer(offer)
    }
    editHandler = (file) => {
        this.setState(prevState => {
            return { ...prevState, selected: file}
        })
    }

    render() {
        const { offers } = this.props

        const config = offersTable(this.editAction, this.deleteAction)
        const filter = <FilterPanel config={offersFiltes} handler={this.handleFilterSelection} />
        return (
            <div className="pageWrapper">
                {this.renderModal()}
                <div>
                    <h2 className="pageTitle">Offers</h2>
                    <Button title="Add offer" handler={()=>this.setState({ isOpen:true , selected: null})} />
                </div>
                <Table config={ config } data={ offers } filter={filter}/>
            </div>
        )
    }

    renderModal = () => {
        const { isOpen, selected } = this.state

        return(
            <OfferCard
                isOpen={isOpen}
                offer={selected}
                handler={this.handleOffer}
            />
        )
    }
}

const mapStateToProps = state => ({
    offers: state.companies.offers,
    isFetching: state.companies.isFetching,
})

const mapDispatchToProps = dispatch => ({
    getOffers: (filters) => dispatch(fetchOffers(filters)),
    addOffer: (offer) => dispatch(addNewOffer(offer)),
    updateOffer: (offer) => dispatch(updateExistingOffer(offer)),
    deleteOffer: (offer) => dispatch(deleteExistingOffer(offer))
})

export default withNavBar(connect(mapStateToProps, mapDispatchToProps)(Offers))
