import {
  addCompany,
  createOffer,
  deleteCompany,
  deleteOffer,
  editCompany,
  getCompanies,
  getCompaniesWithPagination,
  getSpecialOffers,
  makeVIP,
  updateOffer,
} from '../firebase/companiesApi.js';
import {
  ADD_NEW_COMPANY,
  ADD_OFFER,
  CHANGE_COMPANY_VIPSTATUS,
  CHANGE_REQUEST_STATUS,
  DELETE_COMPANY,
  DELETE_OFFER,
  EDIT_COMPANY_INFO,
  FETCH_OFFERS,
  REQUEST_COMPANIES_END,
  REQUEST_COMPANIES_START,
  REQUEST_MORE_COMPANIES_END,
  UPDATE_OFFER,
} from './types.js';
import {getLastIndex, incrementLastIndex} from '../firebase/appInfoApi.js';

const startCompaniesRequest = (data) => ({type: REQUEST_COMPANIES_START, payload: data});
const endCompaniesRequest = (data) => ({type: REQUEST_COMPANIES_END, payload: data});
const endLoadMoreCompaniesRequest = (data) => ({type: REQUEST_MORE_COMPANIES_END, payload: data});
const changeVipStatusAction = (id, status) => ({
  type: CHANGE_COMPANY_VIPSTATUS,
  payload: {id, newStatus: status},
});
const editCompanyInfoAction = (data) => ({type: EDIT_COMPANY_INFO, payload: data});
const deleteCompanyInfoAction = (data) => ({type: DELETE_COMPANY, payload: data});
const addCompanyAction = (data) => ({type: ADD_NEW_COMPANY, payload: data});

const fetchOffersAction = (data) => ({type: FETCH_OFFERS, payload: data});
const addOfferAction = (offer) => ({type: ADD_OFFER, payload: offer});
const updateOfferAction = (offer) => ({type: UPDATE_OFFER, payload: offer});
const deleteOfferAction = (offer) => ({type: DELETE_OFFER, payload: offer});

export const fetchCompaniesWithPaginationInitial = () => {
  return async (dispatch) => {
    dispatch(startCompaniesRequest({data: []}));
    const {data, lastVisible, isAllDataLoaded} = await getCompaniesWithPagination();
    dispatch(
      endCompaniesRequest({
        data,
        lastVisible,
        isAllDataLoaded,
      })
    );
  };
};

export const fetchCompaniesWithPagination = (lastVisible) => {
  return async (dispatch) => {
    dispatch(startCompaniesRequest());
    const {data, lastVisible: newLastVisible, isAllDataLoaded} = await getCompaniesWithPagination(
      lastVisible
    );
    dispatch(
      endLoadMoreCompaniesRequest({
        data,
        lastVisible: newLastVisible,
        isAllDataLoaded,
      })
    );
  };
};

export function fetchCompanies(filters) {
  return async (dispatch) => {
    dispatch(startCompaniesRequest({data: []}));
    const data = await getCompanies(filters);
    dispatch(endCompaniesRequest({data}));
  };
}

export function changeVipStatus(id, status) {
  return async (dispatch) => {
    dispatch(startCompaniesRequest());
    await makeVIP(id, status);
    dispatch(changeVipStatusAction(id, status));
  };
}

export function addNewCompany(data) {
  return async (dispatch) => {
    dispatch(startCompaniesRequest());
    const index = (await getLastIndex()) + 1;
    await incrementLastIndex(index);
    const docRef = await addCompany({...data, companyID: index});
    dispatch(addCompanyAction({id: docRef.id, ...data, companyID: index}));
  };
}

export function editCompanyInfo(data) {
  return async (dispatch) => {
    dispatch(startCompaniesRequest());
    await editCompany(data);
    dispatch(editCompanyInfoAction(data));
  };
}

export function deleteCompanyInfo(data) {
  return async (dispatch) => {
    dispatch(startCompaniesRequest());
    await deleteCompany(data);
    dispatch(deleteCompanyInfoAction(data));
  };
}

export function fetchOffers(filters) {
  return async (dispatch) => {
    dispatch(startCompaniesRequest());
    const data = await getSpecialOffers(filters);
    dispatch(fetchOffersAction(data));
  };
}

export function addNewOffer(offer) {
  return async (dispatch) => {
    const {data, error} = await createOffer(offer);
    error ? alert(error) : dispatch(addOfferAction(data));
  };
}

export function updateExistingOffer(offer) {
  return async (dispatch) => {
    const updatedOffer = await updateOffer(offer);
    dispatch(updateOfferAction(updatedOffer));
  };
}
export function deleteExistingOffer(offer) {
  return async (dispatch) => {
    const deletedOffer = await deleteOffer(offer);
    dispatch(deleteOfferAction(deletedOffer));
  };
}
export const changeRequestStatus = (status) => ({type: CHANGE_REQUEST_STATUS, payload: status});
