import firebase from 'firebase/app';

const _getTimestamp = (epochDate) => new firebase.firestore.Timestamp(epochDate, 0);

const queryDecorator = (dbRef, filters) => {
  const {statusFilter, searchText, dateFilter, stateFilter, isVIP} = filters;

  if (statusFilter && statusFilter !== 'all') {
    dbRef = dbRef.where('status', '==', statusFilter);
  }

  if (dateFilter) {
    dbRef = dbRef.where('dateAdded', '>', _getTimestamp(dateFilter.from));
    dbRef = dbRef.where('dateAdded', '<', _getTimestamp(dateFilter.to));
  }

  if (stateFilter && stateFilter !== 'All') {
    dbRef = dbRef.where('state', '==', stateFilter);
  }

  if (isVIP) {
    dbRef = dbRef.where('isVIP', '==', isVIP);
  }

  if (dateFilter && searchText) {
    dbRef = dbRef.orderBy('dateAdded');
  }

  if (searchText) {
    dbRef = dbRef
      .orderBy('name')
      .startAt(searchText)
      .endAt(searchText + '\uf8ff');
  }

  return dbRef;
};

export default queryDecorator;
