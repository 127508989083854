import React from 'react';
import PropTypes from 'prop-types';
import DropdownFilter from './DropdownFilter.js';
import DateFilter from './DateFilter.js';
import CheckboxFilter from './CheckboxFilter.js';
import MultilevelDropdown from './MultilevelFilter';
import './style.css';

const FilterPanel = ({config, handler, children}) => {
  const createComponent = (info) => {
    switch (info.type) {
      case 'dropdown':
        return <DropdownFilter {...info} handler={handler} key={info.fieldName} />;
      case 'checkbox':
        return <CheckboxFilter {...info} handler={handler} key={info.fieldName} />;
      case 'date':
        return <DateFilter {...info} handler={handler} key={info.fieldName} />;
      case 'multi':
        return <MultilevelDropdown {...info} handler={handler} key={info.fieldName} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="panel_body">{config.map(createComponent)}</div>
      {children}
    </>
  );
};

FilterPanel.propTypes = {
  config: PropTypes.array.isRequired,
  handler: PropTypes.func.isRequired,
};

export default FilterPanel;
