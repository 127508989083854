import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './Companies.scss';
import Table from '../../components/table/Table.js';
import FilterPanel from '../../components/filters/FilterPanel.js';
import withNavBar from '../../hoc/withNavBar.js';
import {
  changeVipStatus,
  fetchCompanies,
  fetchCompaniesWithPagination,
} from '../../redux/companiesActions.js';
import {companiesFilters, companiesTable} from '../../mapper.js';
import {Loader} from '../../components/loader/Loader';
import {DeleteEntity} from '../../components/dev/deleteEntity/deleteEntity';
import {useImmer} from 'use-immer';
import {useHistory} from 'react-router-dom';
import {getPageData} from '../../utils/pagination';
import {removeEmptyValuesFromObj} from '../../utils/object';
import MultilevelDropdown from '../../components/dropdown/DropdownMultilevel';

const initialFiltersState = {
  statusFilter: 'all',
  dateFilter: null,
  stateFilter: 'All',
  isVIP: null,
  searchText: '',
};

const serviceConfig = {field: 'Services'};

/**
 * Firebase limitations
 * https://firebase.google.com/docs/firestore/query-data/order-limit-data
 * https://firebase.google.com/docs/firestore/query-data/queries#compound_queries
 */

const Companies = () => {
  const [state, setState] = useImmer({
    selectedServices: [],
    isFiltering: false,
  });
  const {isFiltering, selectedServices} = state;
  const ref = useRef({...initialFiltersState});
  const {data: companies, lastVisible, isAllDataLoaded, isFetching} = useSelector(
    (state) => state.companies
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchCompaniesWithFilters = useCallback(
    () => dispatch(fetchCompanies(removeEmptyValuesFromObj(ref.current))),
    [dispatch]
  );

  const handleTableChange = useCallback(
    (type, value) => {
      if (type === 'pagination') {
        const {page} = value;

        const data = getPageData(companies, page);

        setState((state) => {
          state.page = page;
          state.isFiltering = false;
        });

        if (isFiltering) return;
        if (data.length) return;
        if (isAllDataLoaded) return;
        dispatch(fetchCompaniesWithPagination(lastVisible));
      }
    },
    [companies, dispatch, isAllDataLoaded, isFiltering, lastVisible, setState]
  );

  const handleServiceFilter = useCallback(
    (field, selectedServices) => {
      setState((state) => {
        state.selectedServices = selectedServices;
      });
    },
    [setState]
  );

  const handleFilterChange = useCallback(
    (field, value) => {
      ref.current[field] = value;
      fetchCompaniesWithFilters();
    },
    [fetchCompaniesWithFilters]
  );

  useEffect(() => {
    !isFetching && fetchCompaniesWithFilters();
    //run only once on mount
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vipStatusChange = useCallback((id, newValue) => dispatch(changeVipStatus(id, newValue)), [
    dispatch,
  ]);

  const editAction = useCallback((id) => history.push(`/edit_company/${id}`), [history]);

  const tableConfig = useMemo(() => companiesTable(editAction, vipStatusChange), [
    editAction,
    vipStatusChange,
  ]);

  const filteredCompanies = useMemo(() => {
    if (!selectedServices.length) return companies;

    return companies.filter((company) => {
      return selectedServices.some((selectedService) =>
        company.Services.some((companyService) => companyService.ID === selectedService.ID)
      );
    });
  }, [companies, selectedServices]);

  return (
    <div className="pageWrapper">
      <h2 className="pageTitle">Companies</h2>
      <DeleteEntity />
      <Table
        config={tableConfig}
        data={filteredCompanies}
        filter={
          <FilterPanel config={companiesFilters} handler={handleFilterChange}>
            <div className="c-services">
              <MultilevelDropdown
                info={serviceConfig}
                value={selectedServices}
                handler={handleServiceFilter}
              />
            </div>
          </FilterPanel>
        }
        onTableChange={handleTableChange}
      />
      {isFetching && <Loader />}
    </div>
  );
};

export default withNavBar(Companies);
