import React from 'react';
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import firebase from '../../firebase/firebaseApp.js'
import logo from '../../media/logo.png'
import logout from '../../media/logout.png'
import PageSelector from '../../components/pageSelector/PageSelector.js'
import './Navbar.css'

const Navbar = () => {

    const logoutUser = async (e) => {
        e.preventDefault();
        await firebase.auth().signOut()
    }

    const userEmail = useSelector( state => state.user.email);
    if(!userEmail) return( <Redirect to="/" /> );

    return (
        <div className="mainNav">
            <img src={logo} className="panelImg" alt="Logo" />
            {process.env.REACT_APP_ENV === 'dev' && <span style={{marginLeft:10}}>Dev environment</span>}
            <PageSelector />
            <button onClick={logoutUser} className="panelLogout">
                {userEmail}
                <img src={logout} className="panelLogoutImg" alt="Logout" />
            </button>
        </div>
    );

};

export default Navbar;
