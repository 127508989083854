import firebase from 'firebase/app';
import cellSwitch from './components/table/CellSwitch.js';
import cellButton from './components/table/CellButton.js';
import cellImage from './components/table/CellImage.js';
import cellStringSwitch from './components/table/CellStringSwitch.js';

const _dateFormatter = (cell) => {
  if (typeof cell !== 'object') return '';
  if (!cell) return;
  return cell?.toDate?.().toLocaleString('en-US');
};

export const companiesTable = (editAction, vipAction) => [
  {
    dataField: 'companyID',
    text: 'ID',
  },
  {
    dataField: 'name',
    text: 'Company',
  },
  {
    dataField: 'status',
    text: 'Status',
    searchable: false,
  },
  {
    dataField: 'dateAdded',
    text: 'Date Added',
    searchable: false,
    formatter: _dateFormatter,
  },
  {
    dataField: 'address',
    text: 'Address',
    searchable: false,
  },
  {
    dataField: 'isVIP',
    text: 'VIP',
    searchable: false,
    formatter: cellSwitch(vipAction),
  },
  {
    dataField: 'dateModified',
    text: 'Date of changes',
    searchable: false,
    formatter: _dateFormatter,
  },
  {
    dataField: '',
    text: 'Edit',
    searchable: false,
    formatter: cellButton(editAction),
  },
];

export const companiesFilters = [
  {
    type: 'date',
    fieldName: 'dateFilter',
  },
  {
    type: 'dropdown',
    fieldName: 'statusFilter',
    options: ['all', 'draft', 'published', 'approved'],
  },
  {
    type: 'dropdown',
    fieldName: 'stateFilter',
    options: [
      'All',
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virgin Island',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ],
  },
  {
    type: 'checkbox',
    fieldName: 'isVIP',
    title: 'Is VIP',
  },
];
export const reviewsFilters = [
  {
    type: 'date',
    fieldName: 'dateFilter',
  },
  {
    type: 'dropdown',
    fieldName: 'sourceFilter',
    options: ['ALL', 'GMB', 'APP'],
  },
];

const days = {
  monday: {from: '09:00 AM', to: '05:00 PM', isClosed: true, '24h': false},
  tuesday: {from: '09:00 AM', to: '05:00 PM', isClosed: true, '24h': false},
  wednesday: {from: '09:00 AM', to: '05:00 PM', isClosed: true, '24h': false},
  thursday: {from: '09:00 AM', to: '05:00 PM', isClosed: true, '24h': false},
  friday: {from: '09:00 AM', to: '05:00 PM', isClosed: true, '24h': false},
  saturday: {from: '09:00 AM', to: '05:00 PM', isClosed: true, '24h': false},
  sunday: {from: '09:00 AM', to: '05:00 PM', isClosed: true, '24h': false},
};

const hoursState = {
  weekDays: days,
  wholeDay: false,
  isVisible: false,
};

export const companyFormState = {
  name: '',
  address: '',
  gmb: '',
  description: '',
  rating: -1,
  email: '',
  isVIP: false,
  location: {lat: 40.071, lng: -101.02},
  priceRange: 0,
  phone: '',
  status: 'draft',
  ownerEmail: '',
  ownerPhone: '',
  ownerName: '',
  ownerDetails: '',
  languages: [],
  payments: [],
  workingHours: hoursState,
  Services: [],
  Tags: [],
  specialOffers: [],
  showMap: false,
  error: null,
  website: '',
  editMode: '',
  reviewsCount: 0,
  customAvailabilityDeadLine: firebase.firestore.Timestamp.now(),
  state: '',
};

export const reviewsTop = [
  {
    dataField: 'companyID',
    text: 'Company ID',
  },
  {
    dataField: 'name',
    text: 'Company Name',
  },
  {
    dataField: 'dateModified',
    text: 'Last Edited',
    searchable: false,
    formatter: _dateFormatter,
  },
  {
    dataField: 'reviewsCount',
    text: 'Reviews Count',
    searchable: false,
  },
];

export const reviewsDetail = (deleteAction) => [
  {
    dataField: 'Date',
    text: 'Date Added',
    searchable: false,
    formatter: _dateFormatter,
  },
  {
    dataField: 'Rating',
    text: 'Rating',
    searchable: false,
  },
  {
    dataField: 'Message',
    text: 'Review',
    searchable: false,
  },
  {
    dataField: 'Author',
    text: 'User info',
    searchable: false,
  },
  {
    dataField: '',
    text: 'Actions',
    searchable: false,
    formatter: cellButton(deleteAction, 'Delete'),
  },
];

export const reviewTemplate = {
  CompanyID: '',
  CompanyName: '',
  CompanyNumber: 0,
  LastEdited: new firebase.firestore.Timestamp.now(),
  Reviews: [],
};

export const requestsTable = (changeStatus) => [
  {
    dataField: 'CompanyNumber',
    text: 'Company ID',
  },
  {
    dataField: 'CompanyName',
    text: 'Company',
  },
  {
    dataField: 'Type',
    text: 'Type',
  },
  {
    dataField: 'Date',
    text: 'Date Added',
    searchable: false,
    formatter: _dateFormatter,
  },
  {
    dataField: 'Request',
    text: 'Requests',
  },
  {
    dataField: 'UserName',
    text: 'User Name',
  },
  {
    dataField: 'UserInfo',
    text: 'User Info',
  },
  {
    dataField: 'Status',
    text: 'Status',
    formatter: cellStringSwitch(changeStatus),
  },
];

export const requestsFiltes = [
  {
    type: 'date',
    fieldName: 'dateFilter',
  },
  {
    type: 'dropdown',
    fieldName: 'typeFilter',
    options: ['All', 'Suggest and Edit', 'Claim this business'],
  },
  {
    type: 'dropdown',
    fieldName: 'statusFilter',
    options: ['All', 'New', 'Approved'],
  },
];

export const offersTable = (editAction, deleteAction) => [
  {
    dataField: 'dateAdded',
    text: 'Date Added',
    searchable: false,
    formatter: _dateFormatter,
  },
  {
    dataField: 'companyNumber',
    text: 'Company ID',
  },
  {
    dataField: 'companyName',
    text: 'Company',
  },
  {
    dataField: 'id',
    text: 'Offer ID',
  },
  {
    dataField: 'description',
    text: 'Description Offer',
  },
  {
    dataField: 'imageURL',
    text: 'Photo',
    formatter: cellImage(),
  },
  {
    dataField: 'url',
    text: 'URL',
  },
  {
    dataField: '',
    text: 'Actions',
    searchable: false,
    formatter: cellButton(editAction, 'Edit', true, deleteAction),
  },
];

export const offersFiltes = [
  {
    type: 'date',
    fieldName: 'dateFilter',
  },
  {
    type: 'multi',
    fieldName: 'typeFilter',
  },
];
