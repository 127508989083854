import firebase from 'firebase';
import {fbNowDate, getFullStateFromAddress} from './utils';
import {geohashForLocation} from 'geofire-common';
import tzLookup from 'tz-lookup';

/**
 *
 * @param data
 * @param isMobile
 * @returns {{}}
 */
export const companyDataAdapter = (data = {}, isMobile = false) => {
  const newData = {...data, dateModified: fbNowDate()};
  const {weekDays} = data.workingHours;

  if (isMobile) {
    newData.location = data.location;
    newData.editMode = 'submit';
  } else {
    const {lat, lng} = data.location;
    newData.location = new firebase.firestore.GeoPoint(lat, lng);
  }

  newData.state = getFullStateFromAddress(data.address);
  newData.status = data.status === 'private' ? 'draft' : data.status;

  const is24HWeek =
    Object.values(weekDays).filter((day) => !day['24h'] && !day.isClosed).length > 0;
  newData.workingHours = {...data.workingHours, wholeDay: !is24HWeek};

  const {latitude, longitude} = newData.location;
  newData.geohash = geohashForLocation([latitude, longitude]);
  newData.timestamp = tzLookup(latitude, longitude);

  removeCompanyUnusedKeys(newData);

  return newData;
};

export const removeCompanyUnusedKeys = (data) => {
  delete data.showMap;
  delete data.error;
};
