import { combineReducers } from 'redux'
import { companiesReducer } from './companiesReducer.js'
import { userReducer } from './userReducer'
import { servicesReducer } from './sevicesReducer.js'
import { reviewsReducer } from './reviewsReducer.js'
import { requestsReducer } from './requestsReducer.js'

export const rootReducer = combineReducers({
    user: userReducer,
    companies: companiesReducer,
    services: servicesReducer,
    reviews: reviewsReducer,
    requests: requestsReducer,
})