import firebase from 'firebase/app';
import {companyFormState, reviewTemplate} from '../mapper';
import {removeEmptyKeysFromObj} from './object';
import {removeCompanyUnusedKeys} from './dataAdapter';
import {geohashForLocation} from 'geofire-common';
import tzLookup from 'tz-lookup';

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const formatDate = ({seconds}) => {
  var date = new Date(seconds * 1000);
  var year = date.getFullYear();
  var month = ('0' + (date.getMonth() + 1)).slice(-2);
  var day = ('0' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};

export const fbNowDate = () => firebase.firestore.Timestamp.now();

export const epochDate = (date) => new Date(date).getTime() / 1000;

export const toObject = (arr) => {
  var rv = {};
  for (var i = 0; i < arr.length; ++i) rv[arr[i].id] = [];
  return rv;
};

// COMPANIES
export const mapImportedCompanies = (data) => {
  const obj = {...companyFormState};
  obj.name = data.name;
  obj.address = data.address;
  obj.gmb = data.url;
  obj.rating = parseFloat(data.rating_total);
  obj.dateAdded = new firebase.firestore.Timestamp(epochDate(data.created_at), 0);
  obj.dateModified = new firebase.firestore.Timestamp(epochDate(data.updated_at), 0);
  obj.location = new firebase.firestore.GeoPoint(parseFloat(data.lat), parseFloat(data.lng));
  obj.phone = data.phones.length > 0 ? data.phones[0] : '';
  obj.id = data.parsed_id;
  obj.website = data.site || '';
  obj.priceRange = data.price_range || 0;
  if (!data.description) {
    obj.description = data.amenities.reduce((acc, val) => acc + `${val}\n`, '');
  } else {
    obj.description = data.amenities.reduce((acc, val) => acc + `\n${val}`, data.description);
  }

  obj.state = getFullStateFromAddress(obj.address);
  obj.workingHours = removeEmptyKeysFromObj(_parseWorkingHours(data.working_hours));

  const {latitude, longitude} = obj.location;
  obj.geohash = geohashForLocation([latitude, longitude]);
  obj.timestamp = tzLookup(latitude, longitude);

  removeCompanyUnusedKeys(obj);

  return obj;
};

export const prepareReviewObject = (company, review, number) => {
  const data = {...reviewTemplate};
  data.CompanyID = company.id;
  data.CompanyNumber = number;
  data.CompanyName = company.name;
  data.Reviews = review;
  return data;
};

const parseState = (code = '') => {
  const textCode = code.trim().split(' ')[0];
  const states = {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FM: 'Federated States Of Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Marshall Islands',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana Islands',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Virgin Islands',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  };
  //return full state name based on it's abbreviation
  return states[`${textCode}`] ?? '';
};

export const getFullStateFromAddress = (address = '') => {
  return parseState(address.split(',')[2]);
};

const _parseWorkingHours = (wh) => {
  let data = JSON.parse(JSON.stringify(companyFormState.workingHours));
  if (!wh) {
    return data;
  }

  data.isVisible = true;
  const wholeWeek = Object.values(wh).reduce(
    (acc, val) => (val === 'Open 24 hours' ? acc : acc + 1),
    0
  );
  if (wholeWeek === 0) {
    for (const k of Object.keys(data.weekDays)) {
      data.weekDays[k]['24h'] = true;
      data.weekDays[k].isClosed = false;
    }
    return {...data, wholeWeek: true};
  }
  // Regular working hours
  Object.entries(wh).forEach((obj) => {
    const [day, timeString] = obj;
    if (timeString === 'Closed') {
      data.weekDays[day] = {from: '09:00 AM', to: '06:00 PM', isClosed: true, '24h': false};
      return;
    } else if (timeString === 'Open 24 hours') {
      data.weekDays[day] = {from: '09:00 AM', to: '07:00 PM', isClosed: false, '24h': true};
      return;
    } else {
      const [from, to] = timeString.split('\u2013').map(_prepareWorkTime);
      data.weekDays[day] = {from, to, isClosed: false, '24h': false};
      return;
    }
  });
  return data;
};
// 0xc34becc5719d928b

const _prepareWorkTime = (time) => {
  if (time.length === 3) return `0${time.slice(0, 1)}:00 ${time.slice(1)}`;
  if (time.length === 4) return `${time.slice(0, 2)}:00 ${time.slice(2)}`;
  if (time.length === 6) return `0${time.slice(0, 4)} ${time.slice(4)}`;
  else return `${time.slice(0, 5)} ${time.slice(5)}`;
};

export const getUid = () => {
  const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return S4() + S4() + '-' + S4() + S4();
};
