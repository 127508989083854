import React from 'react'
import { connect } from 'react-redux'
import Table from '../../components/table/Table.js'
import FilterPanel from '../../components/filters/FilterPanel.js'
import withNavBar from '../../hoc/withNavBar.js'
import { requestsTable, requestsFiltes } from '../../mapper.js'
import { getRequests, refreshStatus, switchStatus } from '../../redux/requestsActions'
import { checkRequestsPrevState, composeRequestsFilters } from '../../components/filters/filterUtilFunctions.js'

class EditRequests extends React.Component {

    state = { 
        statusFilter: 'All',
        dateFilter: null,
        typeFilter: 'All',
    }

    componentDidMount() { 
        const { fetchRequests, isFetching } = this.props
        if (!isFetching) fetchRequests()
    }

    componentWillUnmount() {
        const { clearStatus } = this.props
        clearStatus()
    }

    componentDidUpdate( _, prevState) {
        if(checkRequestsPrevState(prevState, this.state)) {
            const { fetchRequests } = this.props
            fetchRequests(composeRequestsFilters(this.state))
        }
    }

    handleFilterSelection = (field, value) => this.setState({ [field]: value })

    changeStatusAction = (id, status) => this.props.statusSwitch(id, status)

    render() {
        const { requests } = this.props
        const config = requestsTable(this.changeStatusAction)
        const filter = <FilterPanel config={requestsFiltes} handler={this.handleFilterSelection} />

        return (
            <div className="pageWrapper">
                <h2 className="pageTitle">Edit Requests</h2>
                <Table config={ config } data={ requests } filter={filter}/>    
            </div>
        )
    }
}

const mapStateToProps = state => ({
    requests: state.requests.data,
    isFetching: state.requests.isFetching,
})

const mapDispatchToProps = dispatch => ({
    fetchRequests: (filters) => dispatch(getRequests(filters)),
    clearStatus: () => dispatch(refreshStatus()),
    statusSwitch: (id, status) => dispatch(switchStatus(id, status)),
})

export default withNavBar(connect(mapStateToProps, mapDispatchToProps)(EditRequests))