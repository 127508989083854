import React, {useState, useEffect, useCallback, useMemo} from 'react';
import FormContext from './FormContext';
import GeneralDetailsForm from './steps/GeneralDetailsForm';
import ServicesForm from './steps/ServicesForm/ServicesForm';
import ContactsForm from './steps/ContactsForm';
import BusinessDetailsForm from './steps/BusinessDetailsForm';
import SpecialOffersForm from './steps/SpecialOffersForm';
import ConfirmForm from './steps/ConfirmForm';
import MobileForm from './MobileForm';
import {getLevels, getTags} from '../../firebase/servicesApi';
import {getCompany} from '../../firebase/companiesApi';
import {getMobileFormInfo} from '../../firebase/appInfoApi';
import {companyFormState} from '../../mapper';
import {LoaderOverlay} from '../../components/loaderOverlay/LoaderOverlay';

const EditForm = ({match}) => {
  const [serviceGroups, setServiceGroups] = useState([]);
  const [serviceTags, setServiceTags] = useState([]);
  const [appInfo, setAppInfo] = useState({});
  const [formData, setFormData] = useState(companyFormState);
  const [{isLoading, alert}, setState] = useState({
    isLoading: false,
    alert: {
      show: false,
      msg: '',
    },
  });
  const companyId = match.params.id;

  const filterServiceTags = useCallback(
    (services) =>
      serviceTags.filter((t) => services.some((s) => t.ServiceID.includes(s.ID.trim()))),
    [serviceTags]
  );

  const filteredServiceTags = useMemo(() => filterServiceTags(formData.Services), [
    filterServiceTags,
    formData.Services,
  ]);

  const fetchData = useCallback(() => {
    setState((state) => ({...state, isLoading: true, alert: {show: false, msg: ''}}));
    Promise.all([getLevels(), getTags(), getCompany(companyId), getMobileFormInfo()])
      .then(([serviceGroups, serviceTags, formData, info]) => {
        setFormData(formData);
        setServiceGroups(serviceGroups);
        setServiceTags(serviceTags);
        setAppInfo(info);
      })
      .then(() => setState((state) => ({...state, isLoading: false})))
      .catch((res) => {
        setState((state) => ({
          ...state,
          isLoading: false,
          alert: {
            show: true,
            msg:
              res?.code === 'unavailable'
                ? 'No data downloaded. Slow Internet connection'
                : res?.message,
          },
        }));
      });
  }, [companyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <FormContext.Provider value={{formData, setFormData}}>
      {isLoading && <LoaderOverlay />}
      {alert?.show && (
        <div className="position-fixed container" style={{zIndex: 1}}>
          <div className="alert alert-warning" role="alert">
            <div className="mb-2">{alert?.msg}</div>
            <div className="text-right">
              <button type="button" onClick={fetchData} className="btn btn-light">
                Retry
              </button>
            </div>
          </div>
        </div>
      )}

      <MobileForm>
        <GeneralDetailsForm serviceGroups={serviceGroups} />
        {filteredServiceTags.length && <ServicesForm serviceTags={filteredServiceTags} />}
        <ContactsForm />
        <BusinessDetailsForm data={appInfo} />
        {formData.isVIP && <SpecialOffersForm />}
        <ConfirmForm />
      </MobileForm>
    </FormContext.Provider>
  );
};

export default EditForm;
