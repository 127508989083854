import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Login from './pages/login/Login.js'
import Companies from './pages/companies/Companies.js'
import Reviews from './pages/reviews/Reviews.js'
import EditRequests from './pages/editRequests/EditRequests.js'
import NewCompany from './pages/addCompany/NewCompany.js'
import EditCompany from './pages/editCompany/EditCompany.js'
import Offers from './pages/offers/Offers.js'
import Import from './pages/import/Import.js'
import { NotFound } from './pages/notfound/NotFound.js'
import EditForm from './pages/mobileForm/EditForm.js'
import withFirebaseAuthStatus from './hoc/withFirebaseAuthStatus.js'
import './App.css'

function App () {
  return (
    <Router>
      <div className='full'>
        <Switch>
          <Route path={'/'} exact component={Login}/>
          <Route path={'/companies'} component={Companies}/>
          <Route path={'/reviews'} component={Reviews}/>
          <Route path={'/edit_requests'} component={EditRequests}/>
          <Route path={'/add_company'} exact component={NewCompany}/>
          <Route path={'/edit_company/:id'} component={EditCompany}/>
          <Route path={'/offers'} component={Offers}/>
          <Route path={'/import'} component={Import}/>
          <Route path={'/edit/:id'} component={EditForm} />
          <Route path="*" component={NotFound}/>
        </Switch>
      </div>
    </Router>
  )
}

export default withFirebaseAuthStatus(App)
