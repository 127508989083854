import React, {memo, useCallback, useContext, useMemo} from 'react';
import './ServicesForm.scss';
import PropTypes from 'prop-types';
import FormContext from '../../FormContext';
import StepLayout from '../../StepLayout';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import styles from '../../MobileForm.module.css';
import ServiceItem from './ServiceItem';

const ServicesForm = ({serviceTags}) => {
  const {formData, setFormData} = useContext(FormContext);

  const tagsData = useMemo(
    () =>
      serviceTags.map((serviceTag) => {
        const additionalData = formData.Tags.find((tag) => tag.tagId === serviceTag.id);
        const count = additionalData ? {count: additionalData.count, isSelected: true} : {count: 0};
        return {...serviceTag, ...count};
      }),
    [formData.Tags, serviceTags]
  );

  const handleItemChange = useCallback(
    (tagData) => {
      const _tags = [...formData.Tags];
      const tagIndex = _tags.findIndex((tag) => tag.tagId === tagData.id);

      if (tagIndex < 0) return;

      _tags[tagIndex] = {tagId: tagData.id, count: tagData.count};

      setFormData((formData) => ({
        ...formData,
        Tags: _tags,
      }));
    },
    [formData.Tags, setFormData]
  );

  const handleItemSelect = useCallback(
    (tagData) => {
      const isTagInFormData = formData.Tags.find((tag) => tag.tagId === tagData.id);

      const removeTag = () => {
        const _tags = [...formData.Tags];
        const tagIndex = _tags.findIndex((tag) => tag.tagId === tagData.id);
        _tags.splice(tagIndex, 1);
        setFormData((formData) => ({
          ...formData,
          Tags: _tags,
        }));
      };
      const addTag = () => {
        setFormData((formData) => ({
          ...formData,
          Tags: [...formData.Tags, {tagId: tagData.id, count: tagData.count}],
        }));
      };

      isTagInFormData ? removeTag() : addTag();
    },
    [formData.Tags, setFormData]
  );

  return (
    <StepLayout blocked={formData.Tags.length === 0}>
      <h4 className={styles.stepTitle}>Services Type</h4>
      <div>
        <label className={styles.sectionLabel}>Choose services</label>
        <div className={styles.serviceTypesGrid}>
          {tagsData.map((serviceTag) => (
            <ServiceItem
              key={serviceTag.id}
              data={serviceTag}
              selected={serviceTag.isSelected}
              onClick={handleItemSelect}
              onChange={handleItemChange}
            />
          ))}
        </div>
      </div>
      {formData.Tags.length === 0 && (
        <InfoBlock>
          Please, select at least one service type before proceeding to the next step
        </InfoBlock>
      )}
    </StepLayout>
  );
};

ServicesForm.propTypes = {
  serviceTags: PropTypes.array.isRequired,
};

export default memo(ServicesForm);
