import React, { memo } from 'react'
import { check } from '../../assets/svg'

const Checkbox = ({ checked = false }) => (
  <div
    style={{
      display: 'inline-flex',
      height: '24px',
      width: '24px',
      boxSizing: 'border-box',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #104772',
      backgroundColor: checked ? '#104772' : '#ffffff',
      color: '#ffffff'
    }}
  >
    {check(16)}
  </div>
)

export default memo(Checkbox)
