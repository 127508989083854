import React, {memo, useState} from 'react';
import {camera, closeCircle} from '../../assets/svg';
import styles from './ImageUpload.module.css';

const ImageUpload = ({src, onChange}) => {
  const [image, setImage] = useState();

  const imageUploaded = (e) => {
    const image = e.target.files[0];
    setImageAndNotify(image);
  };
  const removeImage = () => {
    URL.revokeObjectURL(image);
    setImageAndNotify(undefined);
  };
  const setImageAndNotify = (image) => {
    setImage(image);
    onChange && onChange(image);
  };

  const imageSrc = image ? URL.createObjectURL(image) : src;

  return (
    <div className={styles.wrapper}>
      {image || imageSrc ? (
        <div className={styles.image}>
          <img width={200} src={imageSrc} alt="Preview" />
          <div onClick={removeImage}>{closeCircle(20)}</div>
        </div>
      ) : (
        <>
          <label className={styles.button}>
            {camera(20)} Add photo
            <input
              className={styles.uploader}
              type="file"
              accept="image/*"
              capture="camera"
              onChange={imageUploaded}
            />
          </label>
        </>
      )}
    </div>
  );
};

export default memo(ImageUpload);
