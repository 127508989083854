const inputs = {
    buttons: {
        titles: ['delete', 'cancel', 'save'],
    },
    isVIP: {
        name: 'isVIP',
        title: 'VIP company',
    },
    name: {
        label: 'Company',
        type: 'text',
        placeholder: 'Company name',
        name: 'name',
    },
    address: {
        label: 'Address',
        type: 'text',
        placeholder: 'Example: 1600 Amphitheatre Parkway, Mountain View, CA',
        name: 'address',
    },
    gmb: {
        label: 'GMB',
        type: 'url',
        placeholder: '',
        name: 'gmb',
    },
    phone: {
        label: 'Contacts',
        type: 'tel',
        placeholder: 'Phone number',
        name: 'phone',
    },
    email: {
        label: null,
        type: 'email',
        placeholder: 'Email',
        name: 'email',
    },
    website: {
        label: null,
        type: 'url',
        placeholder: 'Website',
        name: 'website',
    },
    about: {
        label: 'About us',
        rows: 4,
        placeholder: '',
        name: 'description',
    },
    ownerName: {
        label: 'Location Owner',
        type: 'text',
        placeholder: 'Name',
        name: 'ownerName',
    },
    ownerPhone: {
        label: null,
        type: 'tel',
        placeholder: 'Phone',
        name: 'ownerPhone',
    },
    ownerEmail: {
        label: null,
        type: 'email',
        placeholder: 'Email',
        name: 'ownerEmail',
    },
    ownerDetails: {
        label: null,
        rows: 2,
        placeholder: 'Other details',
        name: 'ownerDetails',
    },
    priceRange: {
        name: 'priceRange',
        label: 'Price range',
        options: [
            {label: "None", value: 0},
            {label: "$", value: 1},
            {label: "$$", value: 2},
            {label: "$$$", value: 3},
            {label: "$$$$", value: 4}
        ]
    },
    status: {
        name: 'status',
        title: 'Status',
        options: ['draft', 'published', 'approved']
    },
    languages: {
        name: 'languages',
        title: 'Supported languages',
        field: 'languages',
    },
    payments:{
        name: 'payments',
        title: 'Payments accepted',
        field: 'payments'
    },
    workingHours: {
        days:['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
        field: 'workingHours',
        title: 'Working Hours',
    },
    services: {
        field: 'Services',
        title: 'Type of business',
    },
    tags: { 
        field: 'Tags',
        title: 'Tags',
    }
}

export default inputs