import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Table from '../../components/table/Table.js';
import FilterPanel from '../../components/filters/FilterPanel.js';
import withNavBar from '../../hoc/withNavBar.js';
import {reviewsFilters, reviewsTop} from '../../mapper.js';
import {deleteReview, fetchReviews} from '../../redux/reviewsActions.js';
import {DeleteEntity} from '../../components/dev/deleteEntity/deleteEntity';
import {Loader} from '../../components/loader/Loader';
import {useImmer} from 'use-immer';
import {ReviewInnerTable} from './ReviewInnerTable';

const Reviews = () => {
  const [state, setState] = useImmer({
    dateFilter: null,
    sourceFilter: 'ALL',
  });
  const {data: reviews, isFetching} = useSelector((state) => state.reviews);
  const dispatch = useDispatch();
  const {dateFilter} = state;

  const handleFilterSelection = useCallback(
    (field, value) => {
      setState((state) => {
        state[field] = value;
      });
    },
    [setState]
  );

  const deleteAction = useCallback((data, docId) => dispatch(deleteReview(data, docId)), [
    dispatch,
  ]);

  const expander = useMemo(
    () => ({
      renderer: (row) => <ReviewInnerTable row={row} deleteAction={deleteAction} filters={state} />,
    }),
    [deleteAction, state]
  );

  useEffect(() => {
    dispatch(fetchReviews());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchReviews({dateFilter}));
  }, [dateFilter, dispatch]);

  return (
    <div className="pageWrapper">
      <h2 className="pageTitle">Reviews</h2>
      <DeleteEntity entity={'reviews'} />
      <Table
        config={reviewsTop}
        data={reviews}
        filter={<FilterPanel config={reviewsFilters} handler={handleFilterSelection} />}
        expander={expander}
      />
      {isFetching && <Loader />}
    </div>
  );
};

export default withNavBar(Reviews);
