import React from 'react'
import PropTypes from 'prop-types'

const Dropdown = ({ info, handler, value }) => {

    const handleSelection = e => handler(info.name, e.target.value)
    return(
        <div className="form-group">
            <label>{info.title}</label>
            <select className="custom-select" 
                onChange={e => handleSelection(e)}
                value={value}
            >
                {info.options.map( option =>
                    <option key={option} value={option}>
                        {option}
                    </option>)}
            </select>
        </div>
    )

}

Dropdown.propTypes = {
    info: PropTypes.object,
    handler: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
}

export default Dropdown