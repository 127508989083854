import React, {memo, useState} from 'react';
import StepContext from './StepContext';
import StepsProgress from './components/StepsProgress/StepsProgress';
import styles from './MobileForm.module.css';

const MobileForm = ({children}) => {
  const [step, setStep] = useState(0);
  const filteredChildren = children.filter((child) => child);
  return (
    <StepContext.Provider value={{step, setStep, steps: filteredChildren.length}}>
      <div className={styles.formWrapper}>
        <StepsProgress step={step + 1} steps={filteredChildren.length} />
        {filteredChildren[step]}
      </div>
    </StepContext.Provider>
  );
};

export default memo(MobileForm);
