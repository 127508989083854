export const removeEmptyKeysFromObj = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([key]) => !!key)
      .map(([k, v]) => [k, v === Object(v) ? removeEmptyKeysFromObj(v) : v])
  );
};

export const removeEmptyValuesFromObj = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([, v]) => !!v)
      .map(([k, v]) => [k, v === Object(v) ? removeEmptyKeysFromObj(v) : v])
  );
};
