const offersFilter = (filters, offers) => {
    const { dateFilter } = filters
    return dateFilter ? dateFiltered(filters, offers) : typeFiltered(filters, offers)
}

const dateFiltered = (filters, offers) => {
    const { dateFilter: { from, to } } = filters
    const dateFiltered = offers.filter(offer => (offer.epoch >= from && offer.epoch <= to))
    return typeFiltered(filters, dateFiltered)
}

const typeFiltered = (filters, offers) => {
    const { typeFilter } = filters
    if(typeFilter === 'All') return offers
    const groups = [...new Set(typeFilter.map(g => g.GroupID))]
    return offers.filter(offer => {
        const match = offer.services.reduce((acc,val) =>{
            return groups.includes(val.GroupID) ? acc + 1: acc
        }, 0)
        return match > 0
    })
}

export default offersFilter