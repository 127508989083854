import fb from './firebaseApp.js'
import queryDecorator from './queryDecorators/requestsQueryDecorator'

const databaseRef = () => fb.firestore().collection('requests/')

export const fetchRequests = async (filters) => {
    let dbRef = databaseRef()
    let fetch, snapshot

    fetch = filters ? queryDecorator(dbRef, filters) : dbRef
    snapshot = await fetch.get();

    const sortByDateDescending = (a, b) => b?.Date?.seconds - a?.Date?.seconds

    return snapshot.docs
        .map(request => ({id: request.id, ...request.data()}))
        .sort(sortByDateDescending)
}

export const changeStatus = async (id, newStatus) => {
    databaseRef().doc(id).update({ Status: newStatus })
}
