import React, {memo, useContext, useState} from 'react';
import {editCompanyMobile} from '../../firebase/companiesApi';
import StepContext from './StepContext';
import FormContext from './FormContext';
import styles from './MobileForm.module.css';
import {Loader} from '../../components/loader/Loader';

const StepLayout = ({blocked, children, onNextStep, onPrevStep}) => {
  const {step, setStep, steps} = useContext(StepContext);
  const {formData} = useContext(FormContext);
  const [isSaving, setIsSaving] = useState(false);

  const prevStep = () => {
    document.scrollingElement.scrollTop = 0;
    setStep(step - 1);
    onPrevStep();
  };

  const nextStep = () => {
    onNextStep(() => {
      document.scrollingElement.scrollTop = 0;
      setStep(step + 1);
    });
  };

  const submitData = (event) => {
    event.preventDefault();

    const handleError = (e) => {
      console.error(e);
      alert('You got an error while submitting.');
    };

    setIsSaving(true);

    const updatedFormData = {...formData};
    //specialOffers data is already updated on corresponding step
    delete updatedFormData.specialOffers;

    editCompanyMobile(updatedFormData)
      .then(() => {
        setIsSaving(false);
        try {
          if (window.Android) window.Android.showToast('success');
          window.postMessage('success');
        } catch (e) {
          handleError(e);
        }
      })
      .catch((e) => {
        setIsSaving(false);
        handleError(e);
      });
  };

  const buttonsTemplate = (step) =>
    step === 0 ? (
      <button
        disabled={blocked}
        onClick={nextStep}
        style={{width: '100%'}}
        className={`${styles.button} ${styles.buttonPrimary}`}
      >
        Start
      </button>
    ) : (
      <>
        {step > 0 && (
          <button onClick={prevStep} className={`${styles.button} ${styles.buttonSecondary}`}>
            Back
          </button>
        )}
        {step < steps - 1 && (
          <button
            disabled={blocked}
            onClick={nextStep}
            className={`${styles.button} ${styles.buttonPrimary}`}
          >
            Next
          </button>
        )}
        {step === steps - 1 && (
          <button
            disabled={blocked || isSaving}
            onClick={submitData}
            className={`${styles.button} ${styles.buttonPrimary}`}
          >
            {isSaving ? <Loader type={'spinner-border-sm'} /> : 'Submit'}
          </button>
        )}
      </>
    );

  return (
    <>
      {children}
      <hr className={styles.horizSplitter} />
      <div className={styles.actionsWrapper}>{buttonsTemplate(step)}</div>
    </>
  );
};

StepLayout.defaultProps = {
  onPrevStep: () => null,
  onNextStep: (emitNextStep) => emitNextStep(),
};

export default memo(StepLayout);
