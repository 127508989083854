import { FETCH_REQUESTS, FETCH_REQUESTS_END,
    FETCH_REQUESTS_REFRESH, SWITCH_REQUEST_STATUS } from './types.js'

const initialState = {
    data: [],
    isFetching: false,
}

const handlers = {
    [FETCH_REQUESTS]: (state) => ({ ...state, isFetching: true }),
    [FETCH_REQUESTS_END]: (state, action) => ({ ...state, data: action.payload }),
    [FETCH_REQUESTS_REFRESH]: (state) => ({ ...state, isFetching: false, }),
    [SWITCH_REQUEST_STATUS]: (state, action) => ({ ...state, data: _switchStatus(state, action.payload)}),
    DEFAULT: state => state
}

export const requestsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}

const _switchStatus = ({ data } , { id, status }) => {
    const newData = [ ...data ]
    const index = newData.findIndex(r => r.id === id)
    if (index !== -1){
        newData[index].Status = status
    } 
    return newData
}