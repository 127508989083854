import React from 'react';
import Switch from 'react-ios-switch';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Geocode from 'react-geocode';
import Button from '../../components/button/button.js';
import Input from '../input/InputCustom.js';
import TextArea from '../../components/textArea/TextArea.js';
import Checkbox from '../../components/checkbox/Checkbox.js';
import Segment from '../../components/segment/Segment.js';
import DropdownSingle from '../../components/dropdown/DropdownSingle.js';
import DropdownMultiple from '../../components/dropdown/DropdownMultiple.js';
import TagsDropdown from '../../components/dropdown/TagsDropdown.js';
import WorkingHours from '../../components/workingHours/WorkingHours.js';
import MultilevelDropdown from '../../components/dropdown/DropdownMultilevel.js';
import ModalMap from '../../components/modalMap/ModalMap.js';
import {geocodeAddress} from '../../firebase/companiesApi';
import {companyFormState} from '../../mapper.js';
import mapper from './inputsMapper.js';
import './styles.css';
import EditTagsCount from './EditTagsCount/EditTagsCount';

export default class Form extends React.Component {
  constructor(props) {
    super(props);
    const {data} = this.props;
    this.state = data ? {...data, showMap: false, error: null} : {...companyFormState};
    Geocode.setApiKey('AIzaSyC0irlGmAWNoRy1Pakixa8ekcq9Ii0GSbU');
  }

  handleTagsCountChange = (data) => {
    this.setState({Tags: data});
  };

  _handleInputs = (event) => this.setState({[event.target.name]: event.target.value});

  _handleButtons = (event) => this.props.handler(event, this.state);

  _handleCompexInputs = (fieldName, value) => this.setState({[fieldName]: value});

  handleTagsChange = (fieldName, newTags) => {
    this.setState((state) => {
      const prevTags = state.Tags;
      const Tags = newTags.map((newTag) => {
        const getCountFromPrevTagState = () => {
          const prevTag = prevTags.find((prevTag) => prevTag.tagId === newTag.tagId);
          return prevTag ? prevTag?.count : 0;
        };
        return {...newTag, count: getCountFromPrevTagState()};
      });
      return {...state, Tags};
    });
  };
  _handleWorkinHours = (weekday, value) => {
    const workingHours = {...this.state.workingHours};
    workingHours.weekDays[weekday] = value;
    this.setState({workingHours});
  };
  // добавить реверс геокодер
  _handleMapSelect = (location) => {
    this._reverseGeocode(location);
    this.setState({location});
  };

  _showMap = (event) => {
    event.preventDefault();
    this.setState({showMap: true});
  };

  _hideMap = () => this.setState({showMap: false});

  _updateWHVisibility = (isVisible) =>
    this.setState({...this.state, workingHours: {...this.state.workingHours, isVisible}});

  _handleAdressField = (event) => {
    event.persist();
    const {value} = event.target;
    this.setState({address: value});
    this._geocodeAdress(value);
  };

  _geocodeAdress = debounce(async (address) => {
    const location = await geocodeAddress(address);
    if (location) this.setState({location});
  }, 500);

  _reverseGeocode = debounce(async ({lat, lng}) => {
    try {
      const response = await Geocode.fromLatLng(lat, lng);
      const address = response.results[0].formatted_address;
      if (address) {
        this.setState({address});
      }
    } catch (error) {
      console.error(error);
    }
  }, 200);

  _renterInput = (info) => (
    <Input info={info} handler={this._handleInputs} value={this.state[info.name]} />
  );

  _renderTextArea = (info) => (
    <TextArea info={info} handler={this._handleInputs} value={this.state[info.name]} />
  );

  _renderCheckbox = (info) => (
    <div style={{margin: '16px 0', fontWeight: 600}}>
      <Checkbox info={info} value={this.state[info.name]} handler={this._handleCompexInputs} />
    </div>
  );

  render() {
    const {isVisible} = this.state.workingHours;
    const lat = this.state.location ? this.state.location.lat : '';
    const lng = this.state.location ? this.state.location.lng : '';
    const disabled = this.props.data ? [false, false, false] : [true, false, false];

    const mapBTN = (title, index) => (
      <div className="button_holder" key={title}>
        <Button title={title} handler={this._handleButtons} disabled={disabled[index]} />
      </div>
    );

    return (
      <>
        <div className="f">
          <ModalMap
            isOpen={this.state.showMap}
            hide={this._hideMap}
            location={this.state.location}
            onSelect={this._handleMapSelect}
          />
          <form>
            <div className="form_buttons">{mapper.buttons.titles.map(mapBTN)}</div>
            <div className="rows_container form_wrapper">
              <div>
                {this._renterInput(mapper.name)}
                <Input
                  info={mapper.address}
                  handler={this._handleAdressField}
                  value={this.state.address}
                />
                <div className="form_container form_map">
                  <Button title={'Open Map'} handler={this._showMap} />
                  <div className="card">
                    <div className="card-body card_center">{`lat: ${lat} lon: ${lng}`}</div>
                  </div>
                </div>
                {this._renterInput(mapper.gmb)}
                <div className="form-group">
                  <label>{mapper.services.title}</label>
                  <MultilevelDropdown
                    info={mapper.services}
                    value={this.state[mapper.services.field]}
                    handler={this._handleCompexInputs}
                  />
                </div>
                <TagsDropdown
                  info={mapper.tags}
                  value={this.state[mapper.tags.field]}
                  handler={this.handleTagsChange}
                  services={this.state[mapper.services.field]}
                />
                <EditTagsCount data={this.state.Tags} onChange={this.handleTagsCountChange} />
                <Segment
                  info={mapper.priceRange}
                  value={this.state[mapper.priceRange.name]}
                  handler={this._handleCompexInputs}
                />
                <DropdownMultiple
                  info={mapper.languages}
                  handler={this._handleCompexInputs}
                  value={this.state[mapper.languages.field]}
                />
                <DropdownMultiple
                  info={mapper.payments}
                  handler={this._handleCompexInputs}
                  value={this.state[mapper.payments.field]}
                />
              </div>

              <div>
                <div>
                  {this._renterInput(mapper.phone)}
                  {this._renterInput(mapper.email)}
                  {this._renterInput(mapper.website)}
                  {this._renderTextArea(mapper.about)}
                </div>
                {this._renderCheckbox(mapper.isVIP)}
                {this._renterInput(mapper.ownerName)}
                {this._renterInput(mapper.ownerPhone)}
                {this._renterInput(mapper.ownerEmail)}
                {this._renderTextArea(mapper.ownerDetails)}
                <DropdownSingle
                  info={mapper.status}
                  value={this.state[mapper.status.name]}
                  handler={this._handleCompexInputs}
                />
              </div>

              <div>
                <div className="workHoursVisibility">
                  <label>Show working hours</label>
                  <Switch
                    checked={isVisible}
                    onColor="#01B9E7"
                    onChange={(checked) => this._updateWHVisibility(checked)}
                  />
                </div>
                <WorkingHours
                  info={mapper.workingHours}
                  handler={this._handleWorkinHours}
                  value={this.state[mapper.workingHours.field]}
                  disabled={!isVisible}
                />
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}

Form.propTypes = {
  data: PropTypes.object,
  handler: PropTypes.func.isRequired,
};
