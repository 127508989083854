import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {plus} from '../assets/svg';
import StepLayout from '../StepLayout';
import OfferDetailsCard from '../components/OfferDetailsCard/OfferDetailsCard';
import styles from '../MobileForm.module.css';
import {
  createOffer,
  deleteOffer,
  getOffersByCompanyId,
  updateOffer,
} from '../../../firebase/companiesApi';
import {LoaderOverlay} from '../../../components/loaderOverlay/LoaderOverlay';
import {useImmer} from 'use-immer';
import FormContext from '../FormContext';
import {descendingSortEntityByDateAdded} from '../../../utils/array';

let offerIdCount = 0;

const ServicesForm = () => {
  const {formData} = useContext(FormContext);
  const {companyID, id} = formData;

  const [{isLoading, offers}, setState] = useImmer({
    offers: [],
    isLoading: false,
  });

  const addLocalOffer = useCallback(() => {
    setState((state) => {
      state.offers = [
        ...state.offers,
        {
          id: ++offerIdCount,
          isLocalOffer: true,
        },
      ];
    });
  }, [setState]);

  const handleOfferChange = useCallback(
    (offer) => {
      setState((state) => {
        const offerIndex = state.offers.findIndex((item) => item.id === offer.id);
        state.offers.splice(offerIndex, 1, offer);
      });
    },
    [setState]
  );

  const loadOffers = useCallback(() => {
    setState((state) => {
      state.isLoading = true;
    });
    getOffersByCompanyId(id)
      .then((offers) => {
        setState((state) => {
          if (offers) {
            state.offers = offers.sort(descendingSortEntityByDateAdded);
          }
          state.isLoading = false;
        });
      })
      .catch(() =>
        setState((state) => {
          state.isLoading = false;
        })
      );
  }, [id, setState]);

  const handleOfferDelete = useCallback(
    (data) => {
      const removeLocalOffer = () => {
        setState((state) => {
          state.isLoading = false;
          const offerIndex = state.offers.findIndex((item) => item.id === data.id);
          offerIndex >= 0 && state.offers.splice(offerIndex, 1);
        });
      };

      const removeOfferFromDb = async () => {
        setState((state) => {
          state.isLoading = true;
        });
        const response = await deleteOffer({...data, companyNumber: companyID});
        response
          ? loadOffers()
          : setState((state) => {
              state.isLoading = false;
            });
      };

      data.isLocalOffer ? removeLocalOffer() : removeOfferFromDb();
    },
    [companyID, loadOffers, setState]
  );

  const handleNextStep = useCallback(
    async (emitNextStep) => {
      const offersAdapter = offers.map((item) => ({...item, companyNumber: companyID}));
      const offersToCreate = offersAdapter.filter((offer) => offer.isLocalOffer);
      const offersToUpdate = offersAdapter.filter(
        (offer) => !offer.isLocalOffer && offer.isOfferChanged
      );

      const handleError = (e) => {
        if (process.env.REACT_APP_ENV === 'dev') {
          alert(JSON.stringify(e));
        }
      };

      const handleOffers = async (offers, action) => {
        await Promise.allSettled(
          offers.map((localOffer) => {
            const _offer = {...localOffer};
            delete _offer.isLocalOffer;
            delete _offer.isOfferChanged;
            delete _offer.isValid;

            if (_offer.imageUrl || _offer.description) {
              return action(_offer);
            }
            return Promise.reject('Not all required fields entered');
          })
        )
          .then((res) => {
            const isError = res.find((item) => item?.value?.error);
            if (isError) handleError(isError.value.error.toString());
          })
          .catch(handleError);
      };

      setState((state) => {
        state.isLoading = true;
      });

      offersToCreate.length && (await handleOffers(offersToCreate, createOffer));
      offersToUpdate.length && (await handleOffers(offersToUpdate, updateOffer));

      setState((state) => {
        state.isLoading = false;
      });
      emitNextStep();
    },
    [companyID, offers, setState]
  );

  const handlePrevStep = useCallback(() => {
    loadOffers();
  }, [loadOffers]);

  const allowAddMoreOffer = useMemo(() => {
    const {description, imageUrl} = offers[0] ?? {};
    return !!description || !!imageUrl;
  }, [offers]);

  const isAllOffersValid = useMemo(() => {
    return offers.every((offer) => (offer.hasOwnProperty('isValid') ? offer.isValid : true));
  }, [offers]);

  useEffect(() => {
    loadOffers();
  }, [loadOffers, setState]);

  useEffect(() => {
    if (!offers.length) {
      setState((state) => {
        state.offers = [
          {
            id: 0,
            isLocalOffer: true,
          },
        ];
      });
    }
  }, [offers, setState]);

  return (
    <StepLayout blocked={!isAllOffersValid} onPrevStep={handlePrevStep} onNextStep={handleNextStep}>
      {isLoading && <LoaderOverlay />}
      <h4 className={styles.stepTitle}>Special offers</h4>
      <div>
        <label className={styles.sectionLabel}>Special offers</label>

        {offers.map((offer) => (
          <OfferDetailsCard
            key={offer.id}
            data={offer}
            onChange={handleOfferChange}
            onDelete={handleOfferDelete}
          />
        ))}

        {allowAddMoreOffer && (
          <button
            onClick={addLocalOffer}
            className={`${styles.button} ${styles.buttonOutlined}`}
            style={{width: '100%'}}
          >
            {plus(16)}
            Add more offers
          </button>
        )}
      </div>
    </StepLayout>
  );
};

export default ServicesForm;
