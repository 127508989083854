import firebase from 'firebase/app';
import fb from './firebaseApp.js';
import queryDecorator from './queryDecorators/reviewsQueryDecorator.js';
import {decrementReviewsCount} from './companiesApi';
import {epochDate} from '../utils/utils';

const databaseRef = () => fb.firestore().collection('reviews/');

const reviewSnapshotAdapter = (data) => {
  return data
    .map((review) => {
      const data = review.data();
      return {id: review.id, count: data.Reviews.length, ...data};
    })
    .filter((review) => review.count > 0)
    .sort(sortByLastEdit);
};

export const getReviewByCompanyId = async (companyId) => {
  const dbRef = databaseRef();
  const snapshot = await dbRef.where('CompanyID', '==', companyId).get();
  return reviewSnapshotAdapter(snapshot.docs)[0];
};

export const getReviews = async (filters) => {
  const dbRef = databaseRef();
  let fetch, snapshot;
  fetch = filters ? queryDecorator(dbRef, filters) : dbRef;
  snapshot = await fetch.get();
  return reviewSnapshotAdapter(snapshot.docs);
};

export const removeReview = async (data, docId) => {
  decrementReviewsCount(docId);
  var payload = data;
  delete payload.docId;
  const docRef = databaseRef().doc(docId);
  return docRef.update({Reviews: firebase.firestore.FieldValue.arrayRemove(payload)});
};
const timer = (ms) => new Promise((res) => setTimeout(res, ms));

export const importReviews = async (url) => {
  try {
    const response = await fetch(url, {
      headers: {
        Authorization: 'Bearer 1|2liYERKSAFPa4w9iETRybJt25luFpBt6EpHJvW2Z',
        'Access-control-allow-origin': '*',
      },
    });
    const {data, next_page_url, last_page} = await response.json();
    await timer(300);
    return {data: data.map(transformReview), next_page_url, last_page};
  } catch (error) {
    console.error(error);
    return {error};
  }
};

const transformReview = (data) => ({
  ID: data.parsed_id,
  Author: data.author_name,
  Message: data.text,
  Moderated: false,
  Rating: data.rating,
  SourceID: 'GMB',
  Date: new firebase.firestore.Timestamp(epochDate(data.publish_date), 0),
});

const sortByLastEdit = (a, b) => (a.LastEdited < b.LastEdited ? 1 : -1);
