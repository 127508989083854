import React, {useCallback} from 'react';
import styles from '../../MobileForm.module.css';
import SVG from 'react-inlinesvg';
import clsx from 'clsx';
import {getEmptyStringFromZeroNumber, getPositiveNumberOrZero} from '../../../../utils/number';

export const ServiceItem = ({data, selected, onClick, onChange}) => {
  const {Icon_SVG, Label, hasNumber, count = 0} = data;

  const handleClick = useCallback(() => onClick(data), [data, onClick]);
  const handleInc = useCallback(() => onChange({...data, count: count + 1}), [
    count,
    data,
    onChange,
  ]);
  const handleDec = useCallback(
    () => onChange({...data, count: getPositiveNumberOrZero(count - 1)}),
    [count, data, onChange]
  );
  const handleChange = useCallback(
    (e) => {
      onChange({...data, count: getPositiveNumberOrZero(parseInt(e.target.value, 10))});
    },
    [data, onChange]
  );

  return (
    <div
      className={clsx(
        'sf-item',
        styles.serviceTypeCard,
        selected && styles.selected,
        selected && hasNumber && 'sf-item--selected'
      )}
      onClick={handleClick}
    >
      {selected && hasNumber ? (
        <>
          <span className="sf-item__title">{Label}</span>
          <div className="sf-item__control" onClick={(e) => e.stopPropagation()}>
            <button
              className="sf-item__control-btn sf-item__control-btn--minus"
              onClick={handleDec}
            />
            <input
              className="sf-item__control-input"
              type="number"
              min="0"
              placeholder="0"
              value={getEmptyStringFromZeroNumber(count)}
              onChange={handleChange}
            />
            <button
              className="sf-item__control-btn sf-item__control-btn--plus"
              onClick={handleInc}
            />
          </div>
        </>
      ) : (
        <>
          <span className="sf-item-icon">
            {count > 1 && <span className="sf-item-icon__label">{count}</span>}
            <SVG src={Icon_SVG} />
          </span>
          <div>{Label}</div>
        </>
      )}
    </div>
  );
};

export default React.memo(ServiceItem);

ServiceItem.defaultProps = {
  onClick: () => null,
  onChange: () => null,
};
