import { FETCH_GROUPS, FETCH_TAGS } from './types.js'

const initialState = {
    groups: [],
    tags: [],
}

const handlers = {
    [FETCH_GROUPS]: (state, action) => ({ ...state, groups: action.payload }),
    [FETCH_TAGS]: (state, action) => ({ ...state, tags: action.payload}),
    DEFAULT: state => state
}

export const servicesReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}