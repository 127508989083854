import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import withNavBar from '../../hoc/withNavBar.js'
import CompanyForm from '../../components/companyForm/CompanyForm.js'
import { editCompanyInfo, deleteCompanyInfo, changeRequestStatus } from '../../redux/companiesActions.js'

class EditCompany extends React.Component {

    componentWillUnmount(){
        const { requestStatusChange } = this.props
        requestStatusChange(false)
    }

    handleSubmit = (event, company) => {
        event.preventDefault()
        switch (event.target.value) {
            case 'cancel': this.props.history.goBack(); break
            case 'save': this.saveAction(company); break
            case 'delete': this.deleteAction(company); break
            default: return
        }
    }

    saveAction = company => this.props.saveCompany(company)

    deleteAction = company => this.props.removeCompany(company)

    _getData = (data,id) => {
        const index = data.findIndex(company => company.id === id)
        return index !== -1 ? data[index] : null
    }

    render() {
        const { companies, requestEnded } = this.props
        if(requestEnded) return <Redirect to="/"/>

        const { id } = this.props.match.params
        const company = this._getData(companies, id)
        if (!company) return null
        return(
            <div className="pageWrapper">
                <h2>EditCompany {company.companyID}</h2>
                <CompanyForm data={company} handler={this.handleSubmit}/>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    companies: state.companies.data,
    requestEnded: state.companies.requestFinished,
})

const mapDispatchToProps = dispatch => ({
    saveCompany: company => dispatch( editCompanyInfo(company) ),
    removeCompany: company => dispatch( deleteCompanyInfo(company) ),
    requestStatusChange: status => dispatch( changeRequestStatus(status) ),
})

export default withNavBar(connect(mapStateToProps, mapDispatchToProps)(EditCompany))