import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {getTags} from '../../firebase/servicesApi.js';
import {FETCH_TAGS} from '../../redux/types.js';

const TagsDropdown = ({info, handler, value, services, className = ''}) => {
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.services.tags);
  const handleSelection = (array) =>
    handler(
      info.field,
      array
        ? array.map((tag) => {
            return {tagId: tag.value, count: 0};
          })
        : []
    );

  useEffect(() => {
    const fetchTags = async () => {
      const data = await getTags();
      dispatch({type: FETCH_TAGS, payload: data});
    };
    if (tags.length === 0) fetchTags();
  }, [tags, dispatch]);

  return (
    <div className={`form-group ${className}`}>
      <label>{info.title}</label>
      <Select
        isMulti
        name="colors"
        defaultValue={value.map(_transformSelected)}
        options={_composeTags(tags, services)}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={(array) => handleSelection(array)}
      />
    </div>
  );
};

const _transformSelected = (obj) => {
  if (obj) {
    if (obj.tagId !== undefined) {
      return {value: obj.tagId, label: obj.tagId.replace(/_/g, ' ')};
    } else {
      return {value: obj, label: obj.replace(/_/g, ' ')};
    }
  }
};

const _composeTags = (tags, services) => {
  const filtered = services.reduce((total, service) => {
    const filteredTags = tags.filter((tag) => tag.ServiceID.includes(service.ID.trim()));
    return total.concat(filteredTags.map((t) => t.id));
  }, []);

  return [...new Set(filtered)].map(_transformSelected);
};

TagsDropdown.propTypes = {
  info: PropTypes.object.isRequired,
  handler: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  services: PropTypes.array,
};

export default TagsDropdown;
