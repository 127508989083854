import React, {useCallback, useEffect} from 'react';
import utilsStyles from '../../MobileForm.module.css';
import ImageUpload from '../ImageUpload/ImageUpload';
import styles from './OfferDetailsCard.module.css';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import {urlPattern} from '../../../../utils/pattern';

const OfferDetailsCard = ({data, onChange, onDelete}) => {
  const {
    register,
    errors,
    trigger,
    formState: {isValid, touched},
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: false,
  });

  const {description, imageUrl, url = ''} = data;
  const handleDescChange = useCallback(
    (e) => {
      const description = e.target.value;
      onChange(
        {
          ...data,
          description,
          isOfferChanged: true,
        },
        isValid
      );
    },
    [data, onChange, isValid]
  );

  const handleUrlChange = useCallback(
    (e) => {
      const url = e.target.value;
      const emitChange = () => {
        onChange({
          ...data,
          url,
          isOfferChanged: true,
          isValid,
        });
      };
      emitChange();
      trigger().then();
    },
    [onChange, data, isValid, trigger]
  );

  const handleImgChange = useCallback(
    (imageUrl) => {
      onChange(
        {
          ...data,
          imageUrl,
          isOfferChanged: true,
        },
        isValid
      );
    },
    [data, onChange, isValid]
  );

  const handleDelete = useCallback(() => onDelete(data), [data, onDelete]);

  useEffect(() => {
    onChange({
      ...data,
      isValid,
    });
    //no need to add data as a dependency
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, onChange]);

  return (
    <form className={styles.wrapper}>
      <div className={styles.header}>
        <label>Offer details</label>
        <button type="button" onClick={handleDelete}>
          Delete
        </button>
      </div>
      <textarea
        value={description}
        onChange={handleDescChange}
        className={utilsStyles.textField}
        placeholder="Description"
      />
      <input
        name="url"
        className={`${utilsStyles.textField} ${
          errors.url && touched.url && 'form-control is-invalid'
        }`}
        placeholder="Url"
        value={url}
        onChange={handleUrlChange}
        ref={register({
          pattern: urlPattern,
        })}
      />
      <ImageUpload src={imageUrl} onChange={handleImgChange} />
    </form>
  );
};

OfferDetailsCard.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

OfferDetailsCard.defaultProps = {
  onChange: () => null,
  onDelete: () => null,
};

export default OfferDetailsCard;
