import React from 'react';
import PropTypes from "prop-types";

export const Loader = ({type}) => {
    return (
        <div className="d-flex justify-content-center">
            <div className={`spinner-border text-primary ${type}`} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
}

Loader.propTypes = {
    type: PropTypes.oneOf(['spinner-border-sm', '']),
}

Loader.defaultProps = {
    type: ''
}
