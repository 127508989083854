import React from 'react'
import PropTypes from 'prop-types'


const Dropdown = ({ options, handler, fieldName }) => {

    const handleSelection = e => handler(fieldName, e.target.value)
    return(
        <div className="filter_common">
            <select className="custom-select" onChange={e => handleSelection(e)}>
                {options.map( option => <option key={option} value={option}>{option}</option>)}
            </select>
        </div>
    )
    
}

Dropdown.propTypes = {
    fieldName: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    handler: PropTypes.func.isRequired,
  }

export default Dropdown