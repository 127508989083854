export const USER_LOGGED_IN_WITH_ID = 'USER_LOGGED_IN_WITH_ID';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export const USER_LOGIN_CHECK_START = 'USER_LOGIN_CHECK_START';
export const USER_LOGIN_CHECK_END = 'USER_LOGIN_CHECK_END';

export const REQUEST_COMPANIES_START = 'FETCHING_COMPANIES_START';
export const REQUEST_COMPANIES_END = 'FETCHING_COMPANIES_END';
export const REQUEST_MORE_COMPANIES_END = 'FETCHING_MORE_COMPANIES_END';
export const CHANGE_REQUEST_STATUS = 'CHANGE_REQUEST_STATUS';
export const CHANGE_COMPANY_VIPSTATUS = 'CHANGE_COMPANY_VIPSTATUS';
export const EDIT_COMPANY_INFO = 'EDIT_COMPANY_INFO';
export const ADD_NEW_COMPANY = 'ADD_NEW_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';

export const AVAILABILLITY_FILTER_SELECT = 'AVAILABILLITY_FILTER_SELECT';
export const TYPE_FILTER_SELECT = 'TYPE_FILTER_SELECT';
export const STATUS_FILTER_SELECT = 'STATUS_FILTER_SELECT';

export const ELEMENT_TABLE_SELECT = 'ELEMENT_TABLE_SELECT';
export const ELEMENT_APPROVED = 'ELEMENT_APPROVED';

export const FETCH_GROUPS = 'FETCH_GROUPS';
export const FETCH_TAGS = 'FETCH_TAGS';

export const REQUEST_REVIEWS_START = 'FETCHING_REVIEWS_START';
export const REQUEST_REVIEWS_END = 'FETCHING_REVIEWS_END';
export const DELETE_REVIEW = 'DELETE_REVIEW';

export const FETCH_REQUESTS = 'FETCH_REQUESTS';
export const FETCH_REQUESTS_END = 'FETCH_REQUESTS_END';
export const FETCH_REQUESTS_REFRESH = 'FETCH_REQUESTS_REFRESH';
export const SWITCH_REQUEST_STATUS = 'SWITCH_REQUEST_STATUS';

export const FETCH_OFFERS = 'FETCH_OFFERS';
export const ADD_OFFER = 'ADD_OFFER';
export const UPDATE_OFFER = 'UPDATE_OFFER';
export const DELETE_OFFER = 'DELETE_OFFER';
