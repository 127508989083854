import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import firebase from '../firebase/firebaseApp.js';
import { 
    USER_LOGGED_IN_WITH_ID, USER_LOGGED_OUT,
} from '../redux/types.js';

const withFirebaseAuthStatus = (Component) => props => {
    const dispatch = useDispatch();
    useEffect(() => {
        firebase.auth().onAuthStateChanged(async (user) => {

            if (!user) {  dispatch({ type: USER_LOGGED_OUT }); return; };

            const currentUser = firebase.auth().currentUser;
            const idTokenResult = await currentUser.getIdTokenResult();
            const { claims : { admin, user_id, email } } = idTokenResult;
            (admin) 
                ? dispatch({ type: USER_LOGGED_IN_WITH_ID, payload: { userID: user_id, email }})
                : await firebase.auth().signOut();
          })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return <Component {...props} />
}

export default withFirebaseAuthStatus;