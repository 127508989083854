import React, { memo, useContext } from 'react'
import PropTypes from 'prop-types'
import FormContext from '../FormContext'
import StepLayout from '../StepLayout'
import ItemDropdownSelector from '../components/ItemDropdownSelector/ItemDropdownSelector'
import Checkbox from '../components/Checkbox/Checkbox'
import InfoBlock from '../components/InfoBlock/InfoBlock'
import styles from '../MobileForm.module.css'

const serviceItemTemplate = (s, selected) => (
  <div className={styles.serviceListItem}>
    <div>{s.Label}</div>
    <Checkbox checked={selected} />
  </div>
)

const GeneralDetailsForm = ({ serviceGroups }) => {
  const { formData, setFormData } = useContext(FormContext)
  const updateServices = (services, group) => {
    const Services = formData.Services.filter(
      ({ GroupID }) => GroupID !== group.id
    ).concat(
      services.map(s => ({
        Availability: 'Available',
        GroupID: s.GroupID,
        ID: s.id
      }))
    )
    setFormData(formData => ({ ...formData, Services, Tags: [] }))
  }

  const selectedServiceIndeces = services => {
    let indeces = []
    services.forEach((s, i) => {
      if (formData.Services.find(({ ID }) => ID === s.id)) indeces.push(i)
    })
    return indeces
  }

  const setName = e => {
    const name = e.target.value
    setFormData(formData => ({ ...formData, name }))
  }

  const setAddress = e => {
    const address = e.target.value
    setFormData(formData => ({ ...formData, address }))
  }

  const stepIsBlocked =
    !(formData.name && formData.address) || formData.Services.length === 0

  return (
    <StepLayout blocked={stepIsBlocked}>
      <h4 className={styles.stepTitle}>Introduce your business</h4>
      <div>
        <label className={styles.sectionLabel}>
          Company's name and address
        </label>
        <input
          className={styles.textField}
          placeholder="Company's name"
          value={formData.name}
          onChange={setName}
        />
        <textarea
          className={styles.textField}
          style={{ height: '72px' }}
          placeholder="Company's address"
          value={formData.address}
          onChange={setAddress}
        />
      </div>
      <hr className={styles.horizSplitter} />
      <div>
        <label className={styles.sectionLabel}>Choose business type</label>
        {serviceGroups.map((group, i) => {
          const selectedIs = selectedServiceIndeces(group.services)
          return (
            <div className={styles.groupWrapper} key={i}>
              <ItemDropdownSelector
                multi
                icon={group.Icon_SVG}
                title={group.Label}
                items={group.services}
                opened={!!selectedIs.length}
                selected={selectedIs}
                onSelect={s => updateServices(s, group)}
              >
                {serviceItemTemplate}
              </ItemDropdownSelector>
            </div>
          )
        })}
      </div>
      {stepIsBlocked && (
        <InfoBlock>
          Please, fill all the data and select at least one service before
          proceeding to the next step
        </InfoBlock>
      )}
    </StepLayout>
  )
}

GeneralDetailsForm.propTypes = {
  serviceGroups: PropTypes.array.isRequired
}

export default memo(GeneralDetailsForm)
