import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './style.css';
import {CONFIG} from '../../config';

const Table = ({config, data, filter, expander, searchInput, ...tableProps}) => {
  return (
    <ToolkitProvider keyField="id" data={data} columns={config} search>
      {(props) => (
        <>
          <div className="table_header">
            {searchInput ? searchInput : <Search.SearchBar {...props.searchProps} />}
            <div className="table_filters">{filter}</div>
          </div>
          <div className="table_body">
            <BootstrapTable
              pagination={paginationFactory({
                sizePerPage: CONFIG.pagination.sizePerPage,
                hideSizePerPage: true,
                showTotal: true,
              })}
              {...props.baseProps}
              {...tableProps}
              expandRow={expander}
              noDataIndication={() => <NoData />}
            />
          </div>
        </>
      )}
    </ToolkitProvider>
  );
};

const NoData = () => <div className="table-noData">No data to display</div>;

Table.propTypes = {
  config: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default Table;
