import firebase from './firebaseApp.js';

const groupsRef = () => firebase.firestore().collection('groups/');
const servicesRef = () => firebase.firestore().collection('services/');
const tagsRef = () => firebase.firestore().collection('tags/');

export const getLevels = async () => {
  const groups = mapResult(await groupsRef().get(), 'services').sort(compare);
  for (const g of groups) {
    g.label = g.Label;
    g.value = g.id.trim();
    g.options = [];
  }
  const services = mapResult(await servicesRef().get(), 'tags');
  for (const service of services) {
    const index = groups.findIndex((g) => g.id === service.GroupID);
    groups[index].services.push({...service, id: service.id.trim()});
  }
  for (const g of groups) {
    g.services = g.services.sort(compare);
    g.options = g.services.map((s) => ({label: s.Label.trim(), value: s.id.trim()}));
  }
  return groups;
};

export const getTags = async () => {
  const tags = mapResult(await tagsRef().get(), 'idk');
  return tags;
};

const mapResult = (snapshot, field) =>
  snapshot.docs.map((snapshot) => ({id: snapshot.id, ...snapshot.data(), [field]: []}));

const compare = (a, b) => {
  if (a.Order < b.Order) return -1;
  if (a.Order > b.Order) return 1;
  return 0;
};

export const getEditableTags = async () => {
  try {
    const editableTags = await tagsRef().where('hasNumber', '==', true).get();
    return editableTags.docs.map((doc) => ({...doc.data(), tagId: doc.id}));
  } catch (e) {
    return [];
  }
};
