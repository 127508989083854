import React, { memo } from 'react'
import { wavingHand } from '../../assets/svg'
import styles from './InfoBlock.module.css'

const icon = wavingHand(32)

const InfoBlock = ({ children }) => (
  <div className={styles.wrapper}>
    {icon}
    <span>{children}</span>
  </div>
)

export default memo(InfoBlock)
