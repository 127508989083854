import React, {useCallback} from 'react';
import {getEmptyStringFromZeroNumber, getPositiveNumberOrZero} from '../../../utils/number';

export const EditTagsCountItem = ({data, onChange}) => {
  const {count, tagId, Label} = data;

  const handleInputChange = useCallback(
    (e) => {
      onChange(getPositiveNumberOrZero(parseInt(e.target.value, 10)), tagId);
    },
    [onChange, tagId]
  );

  const emitIncCount = useCallback(() => {
    onChange(getPositiveNumberOrZero(+count + 1), tagId);
  }, [count, onChange, tagId]);

  const emitDecCount = useCallback(() => {
    onChange(getPositiveNumberOrZero(+count - 1), tagId);
  }, [count, onChange, tagId]);

  return (
    <div className="input-group mb-3">
      <span className="input-group-text mr-4">{Label || tagId.replace('_', ' ')}</span>
      <button type={'button'} className="input-group-text" onClick={emitDecCount}>
        -
      </button>
      <input
        type="number"
        min="0"
        className="form-control form-control--edit-tag"
        placeholder="0"
        value={getEmptyStringFromZeroNumber(count)}
        onChange={handleInputChange}
      />
      <button type={'button'} className="input-group-text" onClick={emitIncCount}>
        +
      </button>
    </div>
  );
};

export default React.memo(EditTagsCountItem);

EditTagsCountItem.defaultProps = {
  onChange: () => null,
};
