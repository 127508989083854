import React, { memo, useState } from 'react'
import SVG from 'react-inlinesvg'
import { useItemSelector } from '../../hooks'
import { arrow } from '../../assets/svg'
import styles from './ItemDropdownSelector.module.css'

const arrowIcon = arrow(16)

const ItemDropdownSelector = ({
  icon,
  title,
  opened: openedProp = false,
  items = [],
  selected: preselected,
  multi = false,
  onSelect,
  children
}) => {
  const [opened, setOpened] = useState(openedProp)
  const [selected, setSelected] = useItemSelector(
    items,
    multi ? preselected : [preselected],
    multi
  )
  const select = i => setSelected(i, onSelect)

  return (
    <div className={opened ? styles.openedDetails : ''}>
      <div className={styles.head} onClick={() => setOpened(!opened)}>
        <SVG src={icon}/>
        <div>{title}</div>
        {arrowIcon}
      </div>
      {opened && (
        <ul className={styles.list}>
          {items.map((s, i) => (
            <li key={i} onClick={() => select(i)}>
              {children(s, selected.includes(i), i)}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default memo(ItemDropdownSelector)
