import React, {useEffect, useRef, useState} from 'react';
import Button from '../button/button.js';
import Input from '../input/InputCustom';
import LoaderInfo from './LoaderProgress';
import firebase from '../../firebase/firebaseApp';
import TagsDropdown from '../../components/dropdown/TagsDropdown.js';
import DropdownSingle from '../../components/dropdown/DropdownSingle';
import MultilevelDropdown from '../../components/dropdown/DropdownMultilevel.js';
import {getCompanies, getCompany, importCompanies} from '../../firebase/companiesApi';
import {importReviews} from '../../firebase/reviewsApi';
import {
  getInfo,
  getLastIndex,
  incrementLastIndex,
  updateImportDate,
} from '../../firebase/appInfoApi';
import {formatDate, prepareReviewObject} from '../../utils/utils';
import {_reviewsUrl, _url, inputInfo, servicesInfo, statusInfo, tagsInfo} from './mapper';
import './style.css';
import {chunkArray} from '../../utils/array';
import {Loader} from '../loader/Loader';
import {LoaderOverlay} from '../loaderOverlay/LoaderOverlay';
import {useImmer} from 'use-immer';

const defaultState = {
  companiesFetched: 0,
  reviewsFetched: 0,
  companiesImported: [],
  reviewsImported: 0,
  isImporting: false,
  isImportFinished: false,
};

const CompaniesLoader = () => {
  const [{allCompanies, isLoadingAllCompanies}, setCompaniesState] = useImmer({
    allCompanies: [],
    isLoadingAllCompanies: false,
  });
  const [
    {companiesFetched, isImporting, isImportFinished, companiesImported, reviewsImported},
    setState,
  ] = useState(defaultState);
  const importRef = useRef({companiesImported: []});
  const [{timestamp, isLoadingTimestamp}, setTimestampState] = useState({
    timestamp: '',
    isLoadingTimestamp: false,
  });
  const [Services, setServices] = useState([]);
  const [Tags, setTags] = useState([]);
  const [status, setStatus] = useState('draft');

  useEffect(() => {
    setTimestampState((state) => ({
      ...state,
      isLoadingTimestamp: true,
    }));
    getInfo('lastImportedDate')
      .then((lastImportedDate) => {
        setTimestampState((state) => ({
          ...state,
          isLoadingTimestamp: false,
          timestamp: formatDate(lastImportedDate),
        }));
      })
      .catch(() => {
        setTimestampState((state) => ({
          ...state,
          isLoadingTimestamp: false,
        }));
      });

    setCompaniesState((state) => {
      state.isLoadingAllCompanies = true;
    });
    getCompanies()
      .then((allCompanies) => {
        setCompaniesState((state) => {
          state.allCompanies = allCompanies;
          state.isLoadingAllCompanies = false;
        });
      })
      .catch(() => {
        setCompaniesState((state) => {
          state.isLoadingAllCompanies = false;
        });
      });
  }, [setCompaniesState]);

  const handleInputs = (fieldName, value) => {
    switch (fieldName) {
      case servicesInfo.field:
        setServices(value);
        break;
      case tagsInfo.field:
        setTags(value);
        break;
      case statusInfo.name:
        setStatus(value);
        break;
      default:
        return;
    }
  };

  /**
   * Load max 300 reviews for 1 company
   * 50 reviews per page
   */
  const fetchReviewsForCompany = async (companyId) => {
    const {data = [], next_page_url, last_page, error} = await importReviews(
      `${_reviewsUrl}${companyId}`
    );
    if (error) {
      console.error('fetchReviewsForCompany:', error);
      return [];
    }

    const promises = [];
    const last = last_page >= 6 ? 6 : last_page;
    for (let i = 2; i <= last; i++) {
      promises.push(importReviews(next_page_url.replace(/.$/, `${i}`)));
    }
    const results = await Promise.all(promises);
    try {
      return results.reduce((acc, curr) => [...acc, ...curr.data], [...data]);
    } catch (e) {
      return [];
    }
  };

  /**
   * Load 50 companies per url request
   */
  const emitImportCompanies = async (db, url) => {
    if (!url) return;

    const {data: companies = [], next, error} = await importCompanies(url);

    if (error) return alert(error);
    if (!companies.length) return;

    const batch = db.batch();
    let lastCompanyIndex = await getLastIndex();
    let companiesToImport = [];

    setState((state) => ({...state, companiesFetched: state.companiesFetched + companies.length}));

    companies.forEach((company) => {
      const isCompanyInDb = allCompanies.find((item) => item.id === company.id);
      if (isCompanyInDb) return;
      lastCompanyIndex += 1;

      const companyRef = db.collection('companies').doc(company.id);
      const companyData = {
        ...company,
        companyID: lastCompanyIndex,
        status,
        Services,
        Tags,
      };

      try {
        batch.set(companyRef, companyData);
        companiesToImport.push(companyData);
      } catch (e) {
        console.warn(e);
      }
    });

    if (companiesToImport.length) {
      await Promise.all([batch.commit(), incrementLastIndex(lastCompanyIndex)]);
      setState((state) => {
        const companiesImported = [...state.companiesImported, ...companiesToImport];
        importRef.current.companiesImported = companiesImported;
        return {...state, companiesImported};
      });
    }

    next && (await emitImportCompanies(db, next));
  };

  const emitImportReviews = async (db, companyIndex, companiesImported) => {
    if (!companiesImported.length) return;

    const company = companiesImported[companyIndex];

    if (!company) return;

    const companyId = company.id;
    const isCompanyInDb = await getCompany(companyId);

    if (!isCompanyInDb) return;

    const companyRef = db.collection('companies').doc(companyId);
    const reviews = await fetchReviewsForCompany(companyId);
    const reviewsCount = reviews?.length ?? 0;
    const reviewRef = db.collection('reviews').doc(companyId);

    Promise.all([
      companyRef.update({reviewsCount}),
      reviewRef.set(prepareReviewObject(company, reviews, company.companyID)),
    ]).then();

    setState((state) => ({...state, reviewsImported: state.reviewsImported + reviews.length}));

    await emitImportReviews(db, companyIndex + 1, companiesImported);
  };

  const handleImport = async () => {
    if (!timestamp) return alert('Select parse date');
    const companyUrl = _url + timestamp;
    const db = firebase.firestore();

    //new import, reset state to default
    setState({...defaultState, isImporting: true});
    importRef.current.companiesImported = [];

    await emitImportCompanies(db, companyUrl);

    const companiesImported = importRef.current.companiesImported;

    //load reviews for imported companies
    if (companiesImported.length) {
      const companiesChunk = chunkArray(companiesImported, 3);
      await Promise.allSettled(
        companiesChunk.map((company) => {
          return emitImportReviews(db, 0, company);
        })
      );
    }

    await updateImportDate();

    setState((state) => ({...state, isImporting: false, isImportFinished: true}));
  };

  return (
    <div className="loadWrap">
      <div className="">
        <Input
          info={inputInfo}
          value={timestamp}
          isLoading={isLoadingTimestamp}
          handler={(e) => {
            setTimestampState((state) => ({
              ...state,
              timestamp: e.target.value,
            }));
          }}
        />
        <DropdownSingle info={statusInfo} value={status} handler={handleInputs} />
        <label>{servicesInfo.title}</label>
        <MultilevelDropdown info={servicesInfo} value={Services} handler={handleInputs} />
        <TagsDropdown info={tagsInfo} value={Tags} handler={handleInputs} services={Services} />
      </div>
      <Button disabled={isImporting || !timestamp} handler={() => handleImport()}>
        {isImporting ? <Loader type={'spinner-border-sm'} /> : 'Import Companies'}
      </Button>
      {(isImporting || isImportFinished) && (
        <LoaderInfo
          isImportFinished={isImportFinished}
          companiesFetched={companiesFetched}
          companiesImported={companiesImported.length}
          reviewsImported={reviewsImported}
        />
      )}
      {isLoadingAllCompanies && <LoaderOverlay />}
    </div>
  );
};

export default CompaniesLoader;
