import {DELETE_REVIEW, REQUEST_REVIEWS_END, REQUEST_REVIEWS_START} from './types.js';
import {removeReview} from '../firebase/reviewsApi.js';
import {getCompaniesWithReviews} from '../firebase/companiesApi';

const startReviewsRequest = () => ({type: REQUEST_REVIEWS_START});
const endReviewsRequest = (data) => ({type: REQUEST_REVIEWS_END, payload: data});
const deleteReviewAction = (data, docId) => ({type: DELETE_REVIEW, payload: {data, docId}});

export function fetchReviews(filters) {
  return async (dispatch) => {
    dispatch(startReviewsRequest());
    const reviews = await getCompaniesWithReviews(filters);
    dispatch(endReviewsRequest(reviews));

    return reviews;
  };
}

export function deleteReview(data, docId) {
  return async (dispatch) => {
    try {
      await removeReview(data, docId);
      dispatch(deleteReviewAction(data, docId));
    } catch (error) {
      console.error(error);
    }
  };
}
