import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ title, children, handler, disabled }) => {

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
      }

      return(
        <button value={title} className="btn btn-outline-primary"
                onClick={e => handler(e)} disabled={disabled}>
            {title ? capitalize(title) : children}
        </button>
      )
}

Button.prototypes = {
  title: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
}

Button.defaultProps = {
  disabled: false,
}

export default Button
