import React, {useState} from 'react';
import DatePicker from 'react-date-picker';
import PropTypes from 'prop-types';
import './style.css';

const DateFilter = ({fieldName, handler}) => {
  const [selected, setSelected] = useState({from: null, to: null});

  const handleDateSelection = (newDate, index) => {
    const state =
      index === 0 ? {from: newDate, to: selected.to} : {from: selected.from, to: newDate};
    setSelected(state);
    if (checkState(state)) {
      executeHandler(state);
    }
  };

  const checkState = (obj) => {
    const {from, to} = obj;
    return from && to && from <= to;
  };

  const executeHandler = (obj) => {
    const to = obj.to;
    to.setHours(23, 59, 59, 999);
    handler(fieldName, {from: epochDate(obj.from), to: epochDate(to)});
  };

  const epochDate = (date) => date.getTime() / 1000;

  const clearCalendar = () => {
    setSelected({from: null, to: null});
    handler(fieldName, null);
  };

  return (
    <div className="filter_date">
      <div className="card panel_body filter_row">
        <DatePicker
          value={selected.from}
          clearIcon={null}
          maxDate={new Date()}
          onChange={(date) => handleDateSelection(date, 0)}
          locale="en-US"
        />
        <DatePicker
          value={selected.to}
          clearIcon={null}
          maxDate={new Date()}
          onChange={(date) => handleDateSelection(date, 1)}
          locale="en-US"
        />
        <button type="button" className="btn btn-sm btn-primary" onClick={() => clearCalendar()}>
          X
        </button>
      </div>
    </div>
  );
};

DatePicker.PropTypes = {
  handler: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default DateFilter;
