import React from 'react'
import PropTypes from 'prop-types'
import DayCell from './DayCellAdmin.js'

const WorkingHours = ({ info, handler, value, disabled }) => { 
    
    const handleWeekdayChange = (workDay, data) => handler(workDay, data)
    return(
        <div className="form-group">
            {info.days.map((day) =>
                <DayCell key={day} workDay={day} 
                    handler={ handleWeekdayChange }
                    data={ value.weekDays[day] }
                    disabled={disabled}
                />)
            }
        </div>
    )

}

WorkingHours.propTypes = {
    info: PropTypes.object,
    value: PropTypes.any,
    handler: PropTypes.func,
}

export default WorkingHours