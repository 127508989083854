import React, { memo, useState } from 'react'
import { arrow } from '../../assets/svg'
import styles from './ItemDropdown.module.css'

const arrowIcon = arrow(16)

const ItemDropdown = ({ icon = '', title, items = [], children, plainStyle = false, open = false}) => {
  const [opened, setOpened] = useState(open)
  const TopBar = (
      <div className={styles.head} onClick={() => setOpened(!opened)}>
        <div>{icon}</div>
        <div>{title}</div>
        {arrowIcon}
      </div>
    )

  return (
    <div className={opened ? styles.openedDetails : ''}>
      {!plainStyle ? TopBar : null}
      {opened && (
        <ul className={styles.list}>
          {items.map((s, i) => (
            <li key={i}>{children(s, i)}</li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default memo(ItemDropdown)
