import React from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import {Loader} from "../loader/Loader";

const Input = ({ info, handler, value, isLoading }) => {

    const label = info.label ? <label>{info.label}</label> : null
    return(
        <div className="form-group form-group--mod-1">
            {label}
            <input type={info.type} className="form-control"
                name={info.name}
                placeholder={info.placeholder}
                value={value}
                onChange={handler}
                accept={info.accept}
                disabled={info.disabled || isLoading}
            />
            {
                isLoading && (
                    <div className="input-loader">
                        <Loader/>
                    </div>
                )
            }
        </div>
    )

}

Input.propTypes = {
    info: PropTypes.object,
    value: PropTypes.any,
    handler: PropTypes.func.isRequired,
}

export default Input
