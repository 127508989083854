import React from 'react'
import { memo } from 'react'
import Progress from '../Progress/Progress'
import styles from './StepsProgress.module.css'

const StepsProgress = ({ step, steps }) => (
  <>
    <label className={styles.label}>
      Step {step}/{steps} <span className='text-white'>Version: {process.env.REACT_APP_GIT_SHA}</span>
    </label>
    <Progress percent={(step / steps) * 100} />
  </>
)

export default memo(StepsProgress)
