import React from 'react';
import Switch from 'react-ios-switch';
import SVG from 'react-inlinesvg';
import FormContext from '../FormContext';
import StepLayout from '../StepLayout';
import ItemSelector from '../components/ItemSelector/ItemSelector';
import ItemDropdownSelector from '../components/ItemDropdownSelector/ItemDropdownSelector';
import ItemDropdown from '../components/ItemDropdown/ItemDropdown';
import Checkbox from '../components/Checkbox/Checkbox';
import TimeRangePicker from '../components/TimeRangePicker/TimeRangePicker';
import {capitalize} from '../../../utils/utils';
import styles from '../MobileForm.module.css';

const PRICES = ['None', '$', '$$', '$$$', '$$$$'];

const priceLevelPillTemplate = (p, selected) => (
  <div className={`${styles.priceLevelPill} ${selected ? styles.selected : ''}`}>
    <div>{p}</div>
  </div>
);

const paymentsItemTemplate = (s, selected) => (
  <div className={styles.paymentMethodItem}>
    <div>
      <SVG src={s.iconSvg} />
      <div>{s.name}</div>
    </div>
    <Checkbox checked={selected} />
  </div>
);

const weekdayItemTemplate = (weekday, updateWeekday, updateWeekdaySchedule) => {
  const {day, isClosed, from, to} = weekday;
  const is24Hour = weekday['24h'];
  return (
    <div className={styles.weekdayItem}>
      <div>
        <div>
          <span>{capitalize(day)}: </span>
          <span style={{color: !isClosed ? 'green' : 'crimson'}}>
            {!isClosed ? 'Open' : 'Closed'}
          </span>
        </div>
        <Switch
          checked={!isClosed}
          onChange={(checked) => updateWeekdaySchedule('isClosed', checked)}
          offColor="#B7C8D5"
          onColor="#01B9E7"
        />
      </div>
      {!isClosed && (
        <>
          <div className={styles.timeRangeCnt}>
            <TimeRangePicker
              disabled={is24Hour}
              className={styles.timeRange}
              from={from}
              till={to}
              onChange={(timeRange) => updateWeekday({timeRange})}
            />
            <div className={styles.workHoursVisibility}>
              <span className={styles.workHoursVisibilityHour}>Open 24H</span>
              <Switch
                checked={is24Hour}
                onChange={(checked) => updateWeekdaySchedule('24h', !checked)}
                offColor="#B7C8D5"
                onColor="#01B9E7"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

class BusinessDetailsForm extends React.PureComponent {
  static contextType = FormContext;
  _weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  _updateWeekday(i, {timeRange: [from, to]}) {
    const {weekDays} = this.context.formData.workingHours;
    weekDays[i] = {...weekDays[i], from, to};
    this._updayWeekdayByField(weekDays);
  }

  _updateWeekdaySchedule(day, field, value) {
    const {weekDays} = this.context.formData.workingHours;
    weekDays[day] = {...weekDays[day], [field]: !value};
    this._updayWeekdayByField(weekDays);
  }

  _updayWeekdayByField = (value) =>
    this.context.setFormData((formData) => ({
      ...formData,
      workingHours: {...formData.workingHours, weekDays: value},
    }));

  _selectedIndeces = (data, selected) => {
    let indeces = [];
    data.forEach((s, i) => {
      if (selected.find((tag) => tag === s.name)) indeces.push(i);
    });
    return indeces;
  };

  _updateLanguages = (languages) =>
    this.context.setFormData((formData) => ({
      ...formData,
      languages: languages.map((l) => l.name),
    }));

  _updatePriceRange = (price) =>
    this.context.setFormData((formData) => ({...formData, priceRange: price.length - 1}));

  _updatePayments = (payments) =>
    this.context.setFormData((formData) => ({...formData, payments: payments.map((p) => p.name)}));

  _updateVisibilityWH = (isVisible) =>
    this.context.setFormData((formData) => ({
      ...formData,
      workingHours: {...formData.workingHours, isVisible},
    }));

  _updateVisibilityWholeDay25 = (wholeDay) =>
    this.context.setFormData((formData) => ({
      ...formData,
      workingHours: {...formData.workingHours, wholeDay},
    }));

  _prepareWeekdays = () => {
    const {weekDays} = this.context.formData.workingHours;
    return this._weekdays.map((w) => {
      const day = weekDays[w];
      return {...day, day: w, timeRange: [day.from, day.to]};
    });
  };

  render() {
    const {
      data: {languages, payments},
    } = this.props;
    const {formData} = this.context;
    const {isVisible} = formData.workingHours;
    const orderedLanguages = [...languages].sort((a, b) => a.order - b.order);

    return (
      <StepLayout>
        <h4 className={styles.stepTitle}>Business description</h4>
        <div>
          <label className={styles.sectionLabel}>Supported languages</label>
          <div className={styles.languagesGrid}>
            <ItemSelector
              multi
              onSelect={this._updateLanguages}
              selected={this._selectedIndeces(orderedLanguages, formData.languages)}
              items={orderedLanguages}
            >
              {(l, selected) => (
                <div className={`${styles.languagePill} ${selected ? styles.selected : ''}`}>
                  <SVG src={l.iconSvg} />
                  <div>{l.label}</div>
                </div>
              )}
            </ItemSelector>
          </div>
        </div>
        <hr className={styles.horizSplitter} />
        <div>
          <label className={styles.sectionLabel}>Price range</label>
          <div className={styles.priceLevelsGrid}>
            <ItemSelector
              onSelect={this._updatePriceRange}
              selected={formData.priceRange}
              items={PRICES}
            >
              {priceLevelPillTemplate}
            </ItemSelector>
          </div>
        </div>
        <hr className={styles.horizSplitter} />
        <div>
          <label className={styles.sectionLabel}>Payments accepted</label>
          <ItemDropdownSelector
            title="Payments list"
            multi
            items={payments}
            onSelect={this._updatePayments}
            selected={this._selectedIndeces(payments, formData.payments)}
          >
            {paymentsItemTemplate}
          </ItemDropdownSelector>
        </div>
        <hr className={styles.horizSplitter} />
        <div>
          <div className={styles.workHoursVisibility}>
            <label className={styles.sectionLabel}>Working hours</label>
            <Switch
              checked={isVisible}
              onChange={this._updateVisibilityWH}
              offColor="#B7C8D5"
              onColor="#01B9E7"
            />
          </div>
          {isVisible && (
            <ItemDropdown multi plainStyle open title="Set time" items={this._prepareWeekdays()}>
              {(w, i) =>
                weekdayItemTemplate(
                  w,
                  this._updateWeekday.bind(this, w.day),
                  this._updateWeekdaySchedule.bind(this, w.day)
                )
              }
            </ItemDropdown>
          )}
        </div>
      </StepLayout>
    );
  }
}

export default BusinessDetailsForm;
