import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Input from '../input/InputCustom';
import TextArea from '../../components/textArea/TextArea.js';
import './style.css';

const OfferCard = ({isOpen, offer, handler}) => {
  const [data, setData] = useState(mapper.state);
  const [url, setUrl] = useState('');

  const isEditMode = !!(offer && offer.id);

  useEffect(() => {
    if (offer) {
      setData(offer);
      setUrl(offer.imageUrl);
    } else {
      setData(mapper.state);
    }
  }, [offer]);
  const inputHandler = (e) => {
    const {name, value} = e.target;
    setData((prevState) => {
      return {...prevState, [name]: value};
    });
  };

  const executeHandler = (action, data) => {
    const {companyNumber} = data ?? {};

    if (action === 'create' && !companyNumber) return alert('Enter required fields');

    setUrl('');
    handler(action, data);
  };
  const imageHandler = (e) => {
    const image = e.target.files[0];
    setData((prevState) => ({...prevState, imageUrl: image}));
    setUrl(URL.createObjectURL(image));
  };

  return (
    <Modal show={isOpen} onHide={handler}>
      <Modal.Header>Add offer</Modal.Header>
      <Modal.Body>
        <div className="offerBody">
          <Input
            info={{...mapper.id, disabled: isEditMode}}
            value={data.companyNumber}
            handler={inputHandler}
          />
          <TextArea info={mapper.about} value={data.description} handler={inputHandler} />
          <Input info={mapper.url} value={data.url} handler={inputHandler} />
          <Input info={mapper.img} value={''} handler={imageHandler} />
          <div>
            Min size: width - 280px, height - 160px
            <br />
            {url ? <img src={url} alt="IMG" className="imageFrame" /> : null}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => executeHandler('close')}>
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => (offer ? executeHandler('update', data) : executeHandler('create', data))}
        >
          Save changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

OfferCard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
};

export default OfferCard;

const mapper = {
  id: {
    label: 'Company ID',
    type: 'text',
    name: 'companyNumber',
    value: '',
  },
  about: {
    label: 'Description',
    rows: 3,
    placeholder: '',
    name: 'description',
  },
  url: {
    label: 'URL(optional)',
    type: 'text',
    name: 'url',
  },
  img: {
    label: 'Select image',
    type: 'file',
    name: 'image',
    accept: '.png, .jpg, .jpeg',
  },
  state: {
    id: '',
    companyNumber: '',
    description: '',
    url: '',
    imageUrl: '',
  },
};
