import React, {useEffect, useMemo} from 'react';
import {reviewsDetail} from '../../mapper';
import BootstrapTable from 'react-bootstrap-table-next';
import {getReviewByCompanyId} from '../../firebase/reviewsApi';
import {useImmer} from 'use-immer';
import {Loader} from '../../components/loader/Loader';

export const ReviewInnerTable = ({row, deleteAction, filters}) => {
  const {id} = row;
  const [{data, isLoading}, setState] = useImmer({
    data: null,
    isLoading: false,
  });

  useEffect(() => {
    setState((state) => {
      state.isLoading = true;
    });

    getReviewByCompanyId(id)
      .then((data) => {
        setState((state) => {
          state.data = data;
          state.isLoading = false;
        });
      })
      .catch(() => {
        setState((state) => {
          state.isLoading = false;
        });
      });
  }, [id, setState]);

  const filteredReviews = useMemo(
    () => (data ? filterReviews(data.Reviews, data.id, filters) : []),
    [data, filters]
  );

  if (isLoading) return <Loader />;

  return data ? (
    <BootstrapTable
      key={id}
      keyField="ID"
      columns={reviewsDetail(deleteAction)}
      data={filteredReviews}
    />
  ) : null;
};

const filterReviews = (reviews, docId, filters) => {
  const {dateFilter, sourceFilter} = filters;
  let data = [...reviews];
  data = sourceFilter === 'ALL' ? data : data.filter((review) => review.SourceID === sourceFilter);
  data = !dateFilter
    ? data
    : data.filter((review) => {
        const date = review.Date.seconds;
        return date >= dateFilter.from && date <= dateFilter.to;
      });
  return data
    .map((review) => ({...review, docId}))
    .sort((a, b) => (a.Date.seconds > b.Date.seconds ? -1 : 1));
};
