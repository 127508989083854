import firebase from 'firebase/app'

const _getTimestamp = epochDate => new firebase.firestore.Timestamp(epochDate, 0)
const _getFirstWord = string => string.split(' ')[0] || ''

const queryDecorator = (dbRefference, filters) => chainFilters(dbRefference, filters);

const chainFilters = (dbRefference, filters) => {
    const { statusFilter } = filters
    if (statusFilter && statusFilter !== 'All') { 
        dbRefference = dbRefference.where("Status", "==", statusFilter)
    }
    return dateDecorator(dbRefference, filters)
}

const dateDecorator = (dbRefference, filters) => {
    const { dateFilter } = filters
    if(dateFilter) {
        dbRefference = dbRefference.where("Date", ">", _getTimestamp(dateFilter.from))
        dbRefference = dbRefference.where("Date", "<", _getTimestamp(dateFilter.to))
    }
    return typeDecorator(dbRefference, filters)
}

const typeDecorator = (dbRefference, filters) => {
    const { typeFilter } = filters
    if (typeFilter && typeFilter !== 'All') { dbRefference = dbRefference.where("Type", "==", _getFirstWord(typeFilter)) }
    return dbRefference
}



export default queryDecorator;