const _removeNullKeys = obj => {
    return Object.entries(obj).reduce((a,[k,v]) => (v == null ? a : (a[k]=v, a)), {})
  }

export const checkCompaniesPrevState = (prevState, state) => {
    
    const { statusFilter, dateFilter, stateFilter, isVIP } = state
    switch(true) {
        case (prevState.statusFilter !== statusFilter): return true
        case (prevState.dateFilter !== dateFilter): return true
        case (prevState.stateFilter !== stateFilter): return true
        case (prevState.isVIP !== isVIP): return true
        default: return false
    }
}

export const composeCompaniesFilters = (props) => {
    const { statusFilter, dateFilter, stateFilter, isVIP } = props
    return _removeNullKeys({ statusFilter, dateFilter, stateFilter, isVIP })
}

export const checkReviewsPrevState = (prevState, state) => {
    
    const { sourceFilter, dateFilter } = state
    switch(true) {
        case (prevState.sourceFilter !== sourceFilter): return true
        case (prevState.dateFilter !== dateFilter): return true
        default: return false
    }
}

export const composeReviewsFilters = (props) => {
    const { sourceFilter, dateFilter } = props
    return _removeNullKeys({ sourceFilter, dateFilter })
}

export const checkRequestsPrevState = (prevState, state) => {
    
    const { statusFilter, dateFilter, typeFilter } = state
    switch(true) {
        case (prevState.statusFilter !== statusFilter): return true
        case (prevState.typeFilter !== typeFilter): return true
        case (prevState.dateFilter !== dateFilter): return true
        default: return false
    }
}

export const composeRequestsFilters = (props) => {
    const { typeFilter, dateFilter, statusFilter } = props
    return _removeNullKeys({ statusFilter, dateFilter, typeFilter })
}

export const checkOffersPrevState = (prevState, state) => {
    
    const { dateFilter, typeFilter } = state
    switch(true) {
        case (prevState.typeFilter !== typeFilter): return true
        case (prevState.dateFilter !== dateFilter): return true
        default: return false
    }
}

export const composeOffersFilters = (props) => {
    const { typeFilter, dateFilter } = props
    return _removeNullKeys({ dateFilter, typeFilter })
}