import React, {useCallback, useContext} from 'react';
import FormContext from '../FormContext';
import StepLayout from '../StepLayout';
import styles from '../MobileForm.module.css';
import InputMask from 'react-input-mask';
import {useForm} from 'react-hook-form';
import {emailPattern, urlPattern, usPhonePatter} from '../../../utils/pattern';

const ContactsForm = () => {
  const {formData, setFormData} = useContext(FormContext);
  const {
    register,
    errors,
    formState: {isValid, touched},
    trigger,
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: false,
  });

  const updateFormDataField = useCallback(
    (e) => {
      const {name, value} = e.target;
      setFormData((formData) => ({...formData, [name]: value}));
      trigger().then();
    },
    [setFormData, trigger]
  );

  return (
    <StepLayout blocked={!isValid}>
      <form>
        <h4 className={styles.stepTitle}>Contacts</h4>
        <div>
          <label className={styles.sectionLabel}>Contacts</label>
          <InputMask
            mask="+1\ 999 999 9999"
            maskChar=" "
            name="phone"
            onChange={updateFormDataField}
            value={formData.phone}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                name="phone"
                type="tel"
                className={`${styles.textField} ${
                  errors.phone && touched.phone && 'form-control is-invalid'
                }`}
                placeholder="Phone number"
                ref={register({
                  required: true,
                  pattern: usPhonePatter,
                })}
              />
            )}
          </InputMask>
          <input
            name="email"
            value={formData.email}
            onChange={updateFormDataField}
            className={`${styles.textField} ${
              errors.email && touched.email && 'form-control is-invalid'
            }`}
            placeholder="Email"
            ref={register({
              pattern: emailPattern,
            })}
          />
          <input
            name="website"
            value={formData.website}
            onChange={updateFormDataField}
            className={`${styles.textField} ${
              errors.website && touched.website && 'form-control is-invalid'
            }`}
            placeholder="Website"
            ref={register({
              pattern: urlPattern,
            })}
          />
          <textarea
            name="description"
            value={formData.description}
            onChange={updateFormDataField}
            className={styles.textField}
            style={{height: '200px'}}
            placeholder="Abous us (optional)"
          />
        </div>
      </form>
    </StepLayout>
  );
};

export default ContactsForm;
