import React from 'react';
import './style.css';

const cellImage = () => {
  return (cell, row) => {
    return <div>{row.imageUrl && <img src={row.imageUrl} alt="IMG" className="table-img" />}</div>;
  };
};

export default cellImage;
