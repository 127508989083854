import firebase from 'firebase/app';
import fb from './firebaseApp.js';
import queryDecorator from './queryDecorators/companiesQueryDecorator.js';
import offersFilter from './queryDecorators/offersQueryDecorator';
import {getUid, mapImportedCompanies} from '../utils/utils';
import {companyDataAdapter} from '../utils/dataAdapter';
import {descendingSortEntityByDateAdded} from '../utils/array';
import {removeEmptyValuesFromObj} from '../utils/object';
import {CONFIG} from '../config';

const companySnapshotAdapter = (snapshot) => {
  return (
    mapResult(snapshot)
      /**
       * We cannot exclude private on the query because of restrictions
       * https://firebase.google.com/docs/firestore/query-data/queries#limitations
       *
       * Have to exclude on the client
       */
      .filter((c) => c.status !== 'private')
      .filter((c) => !!c.companyID)
      .sort(descendingSortEntityByDateAdded)
  );
};

const companyReviewsSnapshotAdapter = (snapshot) => {
  return mapResult(snapshot)
    .filter((c) => c.status !== 'private')
    .filter((c) => c.reviewsCount > 0)
    .sort(descendingSortEntityByDateAdded);
};

export const getCompaniesWithPagination = async (lastVisible) => {
  const dbRef = databaseRef();
  let request = dbRef.orderBy('dateAdded', 'desc').limit(CONFIG.pagination.sizePerPage);

  if (lastVisible) {
    request = request.startAfter(lastVisible).limit(CONFIG.pagination.sizePerPage);
  }

  const snapshot = await request.get();

  const isAllDataLoaded = !snapshot.docs.length;

  return {
    data: companySnapshotAdapter(snapshot),
    lastVisible: isAllDataLoaded ? null : snapshot.docs[snapshot.docs.length - 1],
    isAllDataLoaded,
  };
};

export const getCompaniesWithReviews = async (filters) => {
  let dbRef = databaseRef();
  let fetch, snapshot;
  if (filters) {
    fetch = queryDecorator(dbRef, filters);
    snapshot = await fetch.get();
  } else {
    snapshot = await dbRef.orderBy('dateAdded', 'desc').get();
  }
  return companyReviewsSnapshotAdapter(snapshot);
};

export const getCompanies = async (filters) => {
  const dbRef = databaseRef();
  let fetch, snapshot;
  if (filters) {
    fetch = queryDecorator(dbRef, filters);
    snapshot = await fetch.get();
  } else {
    snapshot = await dbRef.orderBy('dateAdded', 'desc').get();
  }
  return companySnapshotAdapter(snapshot);
};

export const getCompany = async (id) => {
  const doc = await databaseRef().doc(id).get();
  return doc.data() !== undefined ? {...doc.data(), id: doc.id} : undefined;
};

export const approveCompany = async (id) => databaseRef().doc(id).update({status: 'approved'});

export const makeVIP = async (id, newStatus) => databaseRef().doc(id).update({isVIP: newStatus});

export const editCompany = async (data) =>
  databaseRef().doc(data.id).update(companyDataAdapter(data));

export const editCompanyMobile = async (data) =>
  databaseRef().doc(data.id).update(companyDataAdapter(data, true));

export const deleteCompany = async (data) => databaseRef().doc(data.id).delete();

export const addCompany = async (data) => {
  // const index = await fb.firestore().collection('appInfo/').get()
  // const lastIndex = index.docs[0].data()
  const now = firebase.firestore.Timestamp.now();
  return databaseRef().add(companyDataAdapter({...data, dateAdded: now, dateModified: now}));
};

export const geocodeAddress = async (address) => {
  const urlBody = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
  const key = '&key=AIzaSyC0irlGmAWNoRy1Pakixa8ekcq9Ii0GSbU';
  try {
    const response = await fetch(`${urlBody}${encodeURIComponent(address)}${key}`);
    const data = await response.json();
    const results = data.results;
    return results.length > 0 ? results[0].geometry.location : null;
  } catch (error) {
    return null;
  }
};

export const importCompanies = async (url) => {
  try {
    const response = await fetch(url, {
      headers: {Authorization: 'Bearer 1|2liYERKSAFPa4w9iETRybJt25luFpBt6EpHJvW2Z'},
    });
    const {data, next_page_url} = await response.json();
    return {data: data.map(mapImportedCompanies), next: next_page_url};
  } catch (error) {
    console.error(error);
    return {error};
  }
};

export const decrementReviewsCount = async (id) => {
  try {
    databaseRef()
      .doc(id)
      .update({reviewsCount: firebase.firestore.FieldValue.increment(-1)});
  } catch (error) {
    console.error(error);
  }
};

export const getSpecialOffers = async (filters) => {
  const companies = await getCompanies();
  const offers = companies
    .filter((c) => c.specialOffers.length > 0)
    .flatMap((c) => {
      return c.specialOffers.map((offer) => _mapOffer(c, offer));
    });
  return filters ? offersFilter(filters, offers) : offers;
};

/**
 * @param companyId
 * @returns {Promise<null|[]>}
 */
export const getOffersByCompanyId = async (companyId = '') => {
  if (!companyId) return null;
  const snapshot = await databaseRef().doc(companyId).get();
  if (!snapshot.exists) return null;
  return snapshot.get('specialOffers');
};

export const createOffer = async (offer) => {
  const snapshot = await databaseRef().where('companyID', '==', Number(offer.companyNumber)).get();
  if (!snapshot.size) {
    return {
      error: `No company found with id:${offer.companyNumber}`,
    };
  }

  try {
    const doc = snapshot.docs[0];
    const company = doc.data();
    const docRef = doc.ref;
    const ref = fb.storage().ref();

    let imageUrl = null;
    if (offer.imageUrl) {
      await ref.child(`offers/${doc.id}/${offer.imageUrl.name}`).put(offer.imageUrl);
      imageUrl = await ref.child(`offers/${doc.id}/${offer.imageUrl.name}`).getDownloadURL();
    }

    const newOffer = _newOffer(offer, imageUrl);
    await docRef.update({specialOffers: firebase.firestore.FieldValue.arrayUnion(newOffer)});

    return {
      data: _mapOffer(company, newOffer),
      error: null,
    };
  } catch (error) {
    return {
      error,
    };
  }
};

export const deleteOffer = async (offer) => {
  const snapshot = await databaseRef().where('companyID', '==', Number(offer.companyNumber)).get();
  if (!snapshot.size) {
    return {
      error: `No company found with id:${offer.companyNumber}`,
    };
  }

  try {
    const doc = snapshot.docs[0];
    const docRef = doc.ref;
    const company = doc.data();
    const offers = company.specialOffers.filter((item) => item.id !== offer.id);
    await docRef.update({specialOffers: offers});
    return offer;
  } catch (error) {
    return {
      error,
    };
  }
};

export const updateOffer = async (offer) => {
  const snapshot = await databaseRef().where('companyID', '==', Number(offer.companyNumber)).get();
  if (!snapshot.size) {
    return {
      error: `No company found with id:${offer.companyNumber}`,
    };
  }

  try {
    const doc = snapshot.docs[0];
    const docRef = doc.ref;
    const company = doc.data();
    const ref = fb.storage().ref();
    let imageUrl = offer.imageUrl;
    if (offer.imageUrl && !offer.imageUrl.toString().includes('firebasestorage')) {
      await ref.child(`offers/${doc.id}/${offer.imageUrl.name}`).put(offer.imageUrl);
      imageUrl = await ref.child(`offers/${doc.id}/${offer.imageUrl.name}`).getDownloadURL();
    }
    const newOffer = _newOffer(offer, imageUrl);
    if (company.specialOffers.length) {
      const offers = company.specialOffers.filter((item) => item.id !== offer.id);
      await docRef.update({specialOffers: offers});
      await docRef.update({specialOffers: firebase.firestore.FieldValue.arrayUnion(newOffer)});
    } else {
      await docRef.update({specialOffers: firebase.firestore.FieldValue.arrayUnion(newOffer)});
    }
    return {newOffer: _mapOffer(company, newOffer), offer};
  } catch (error) {
    return {
      error,
    };
  }
};

// Private
const databaseRef = () => fb.firestore().collection('companies/');
const mapResult = (snapshot) =>
  snapshot.docs.map((snapshot) => {
    const data = snapshot.data();
    const geopoint = data.location
      ? {lat: data.location.latitude, lng: data.location.longitude}
      : null;
    return {id: snapshot.id, ...data, location: geopoint};
  });

const _newOffer = (offer, imageUrl) => {
  const now = firebase.firestore.Timestamp.now();
  const _imageUrl = imageUrl ? {imageUrl} : {};
  return {...removeEmptyValuesFromObj(offer), ..._imageUrl, dateAdded: now, id: getUid()};
};

const _mapOffer = (company, offer) => ({
  ...offer,
  companyNumber: company.companyID,
  companyName: company.name,
  services: company.Services,
  epoch: offer.dateAdded.seconds,
});
