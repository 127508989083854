import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import { getInfo } from '../../firebase/appInfoApi.js'

const Dropdown = ({ info, handler, value }) => {

    const handleSelection = (selected) => {
        if(selected) {
            handler(info.field, selected.map(obj => obj.value))
        }
    }
    const transformState = title => ({ value: title, label: title })
    const transformData = obj => ({ value: obj.name, label: obj.name })

    const [options, setOptions] = useState([])

    useEffect(() => {
        let cleanupFunction = false
        const fetchData = async () => { 
            const data = await getInfo(info.name)
            if(!cleanupFunction) setOptions(data.map(transformData))
        }
        fetchData()
        
        return () => { cleanupFunction = true }
     }, [info.name])

    return(
        <div className="form-group">
            <label>{info.title}</label>
            <Select
                isMulti
                name="colors"
                defaultValue={value.map(transformState)}
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={ array => handleSelection(array) }
            />
        </div>
    )

}

Dropdown.propTypes = {
    info: PropTypes.object.isRequired,
    handler: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired,
}

export default Dropdown