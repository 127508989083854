/**
 * chunkArray([1,2,3,4,5,6],2);
 * returns [[1,2,3],[4,5,6]]
 *
 * @param arr
 * @param n
 * @returns {[]}
 */
export const chunkArray = (arr, n) => {
  const chunkLength = Math.max(arr.length / n, 1);
  const chunks = [];
  for (let i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arr.length)
      chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
  }
  return chunks;
};

export const descendingSortEntityByDateAdded = (a, b) => b.dateAdded.seconds - a.dateAdded.seconds;
