import { useState } from 'react'

export const useItemSelector = (items, preselected = [], multi = false) => {
  const [selected, setSelected] = useState(preselected)

  const select = (i, cb) => {
    let _selected = [...selected]

    const j = _selected.indexOf(i)
    if (j === -1) {
      if (multi) _selected.push(i)
      else _selected = [i]
    } else _selected.splice(j, 1)

    setSelected(_selected)

    if (cb) {
      const selectedItems = _selected.map(i => items[i])
      cb(multi ? selectedItems : selectedItems[0])
    }
  }

  return [selected, select]
}
