import React from 'react'
import { memo } from 'react'
import styles from './Progress.module.css'

const Progress = ({ percent }) => (
  <div className={styles.body}>
    <div
      style={{
        width: `${percent}%`
      }}
      className={styles.filled}
    ></div>
  </div>
)

export default memo(Progress)
