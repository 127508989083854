import React, {useState} from 'react';
import './deleteEntity.scss';
import firebase from '../../../firebase/firebaseApp';
import {LoaderOverlay} from '../../loaderOverlay/LoaderOverlay';

export const DeleteEntity = ({entity}) => {
  const [count, setCount] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const emitDelete = () => {
    const db = firebase.firestore();
    const batch = db.batch();
    setIsLoading(true);

    const orderBy = entity === 'companies' ? 'dateAdded' : 'LastEdited';

    db.collection(entity)
      .orderBy(orderBy, 'desc')
      .limit(count)
      .get()
      .then(async (snapshot) => {
        snapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();
        window.location.reload();
      });
  };

  //temporary allow use this on prod
  // if (process.env.REACT_APP_ENV !== 'dev') return null;

  return (
    <>
      <br />
      {/*For dev*/}
      <div className="delete-comp">
        <button type="button" className="btn btn-sm btn-primary" onClick={() => emitDelete()}>
          Delete
        </button>{' '}
        last <input value={count} onChange={(e) => setCount(+e.target.value)} /> {entity}
      </div>
      {isLoading && <LoaderOverlay />}
    </>
  );
};

export default React.memo(DeleteEntity);

DeleteEntity.defaultProps = {
  entity: 'companies',
};
