import React, {useCallback, useEffect} from 'react';
import './EditTagsCount.scss';
import {EditTagsCountItem} from './EditTagsCountItem';
import {useImmer} from 'use-immer';
import {getEditableTags} from '../../../firebase/servicesApi';
import {Loader} from '../../loader/Loader';

const EditTagsCount = ({data, onChange}) => {
  const [{isLoading, editableTagIds, tags}, setState] = useImmer({
    isLoading: false,
    editableTagIds: [],
    tags: [],
  });

  const handleTagChange = useCallback(
    (count, tagId) => {
      const _data = [...data];
      const elIndex = _data.findIndex((item) => item.tagId === tagId);
      if (elIndex >= 0) {
        _data[elIndex].count = count;
        onChange(_data);
      }
    },
    [data, onChange]
  );

  useEffect(() => {
    setState((state) => {
      state.tags = data
        .map((item) => {
          const itemData = editableTagIds.find(
            (editableTagIds) => editableTagIds.tagId === item.tagId
          );
          if (itemData) {
            return {...item, Label: itemData.Label};
          }
          return null;
        })
        .filter((item) => item);
    });
  }, [data, editableTagIds, setState]);

  useEffect(() => {
    setState((state) => {
      state.isLoading = true;
    });
    getEditableTags()
      .then((res) => {
        setState((state) => {
          state.editableTagIds = res;
          state.isLoading = false;
        });
      })
      .catch(() => {
        setState((state) => {
          state.isLoading = false;
        });
      });
  }, [setState]);

  if (isLoading) return <Loader />;

  return tags.map((item) => (
    <EditTagsCountItem key={item.tagId} data={item} onChange={handleTagChange} />
  ));
};

export default React.memo(EditTagsCount);

EditTagsCount.defaultProps = {
  onChange: () => null,
};
