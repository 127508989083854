import React from 'react'
import Importer from '../../components/companiesLoader/Loader'
import withNavBar from '../../hoc/withNavBar.js'

const Import = props => {

        return (
            <div className="pageWrapper">
                <div>Import</div>
                <Importer />
            </div>
        )
}

export default withNavBar(Import)