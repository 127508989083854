export const inputInfo = {label: 'Last parsed date', type: 'date', placeholder: 'Select date', name: 'date',}
export const servicesInfo = {field: 'Services', title: 'Type of business',}
export const tagsInfo = {field: 'Tags', title: 'Tags',}
export const statusInfo = {name: 'status', title: 'Status', options: ['draft', 'published', 'approved']}

/**
 * To avoid Cors errors use one of the services
 * https://crossorigin.me/
 * https://thingproxy.freeboard.io/fetch/
 *
 * with chrome extension (https://chrome.google.com/webstore/detail/cors-unblock/lfhmikememgdcahcdlaciloancbhjino?hl=en)
 */
// const noCorsService = process.env.REACT_APP_ENV === 'dev' ? 'https://thingproxy.freeboard.io/fetch/' : '';
const noCorsService = '';

export const _url = noCorsService + 'https://map.truckmaster.app/api/parsed-companies/?created_since='
export const _reviewsUrl = noCorsService + 'https://map.truckmaster.app/api/parsed-reviews/?created_since=2020-01-01&company_name='
