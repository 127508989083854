import React, { useState } from 'react'
import styles from './TimeRangePicker.module.css'

const TimeRangePicker = ({
  className = '',
  from: fromProp = '09:00 AM',
  till: tillProp = '05:00 PM',
  onChange, disabled = false,
}) => {
  const [[from, till], setTimeRange] = useState([fromProp, tillProp])

  const timeChanged = (from, till) => {
    onChange && onChange([from, till])
    setTimeRange([from, till])
  }

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <select disabled={disabled} value={from} onChange={e => timeChanged(e.target.value, till)}>
        {TIME_OPTIONS.map(t => (
          <option key={t} value={t}>
            {t}
          </option>
        ))}
      </select>
      <span>-</span>
      <select disabled={disabled} value={till} onChange={e => timeChanged(from, e.target.value)}>
        {TIME_OPTIONS.filter(after(from)).map(t => (
          <option key={t} value={t}>
            {t}
          </option>
        ))}
      </select>
    </div>
  )
}
/*eslint no-unused-vars: 0*/
const timeOptToNumber = opt => {
  const [_, hours, minutes, abbr] = opt.match(/(\d{2}):(\d{2})\s(\w{2})/)
  const nHours = hours === 12 ? 0 : parseInt(hours)
  const fullHours = abbr === 'PM' ? nHours + 12 : nHours
  return fullHours + minutes / 60
}

const compareTimeOpts = (a, b) => {
  const aVal = timeOptToNumber(a)
  const bVal = timeOptToNumber(b)
  return aVal < bVal ? 1 : aVal > bVal ? -1 : 0
}

 // const before = time => opt => compareTimeOpts(opt, time) === 1

const after = time => opt => compareTimeOpts(opt, time) === -1

const TIME_OPTIONS = [
  '12:00 AM',
  '12:30 AM',
  '01:00 AM',
  '01:30 AM',
  '02:00 AM',
  '02:30 AM',
  '03:00 AM',
  '03:30 AM',
  '04:00 AM',
  '04:30 AM',
  '05:00 AM',
  '05:30 AM',
  '06:00 AM',
  '06:30 AM',
  '07:00 AM',
  '07:30 AM',
  '08:00 AM',
  '08:30 AM',
  '09:00 AM',
  '09:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '01:00 PM',
  '01:30 PM',
  '02:00 PM',
  '02:30 PM',
  '03:00 PM',
  '03:30 PM',
  '04:00 PM',
  '04:30 PM',
  '05:00 PM',
  '05:30 PM',
  '06:00 PM',
  '06:30 PM',
  '07:00 PM',
  '07:30 PM',
  '08:00 PM',
  '08:30 PM',
  '09:00 PM',
  '09:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM'
]

export default TimeRangePicker
