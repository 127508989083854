import React from 'react'

const cellButton = (action,title = 'Edit',secondButton = false, secondAction) => {
    return (cell, row) => {
        return (
            <div>
                <button type="button" className="btn btn-link btn-dec" onClick={() => _handler(row, action)}>
                    {title}
                </button>
                {secondButton ? (
                <button type="button" className="btn btn-link btn-dec" onClick={() => _handler(row, secondAction)}>
                    Delete
                </button>
                ): null}
            </div>
        )
    }
}

const _handler = (row, action) => row.id ? action(row.id) : action(row, row.docId)

export default cellButton