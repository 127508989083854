import React from 'react'

const cellSwitch = (action) => {
    return (cell, row) => {
        return (
            <div className="custom-control custom-switch">
                <input type="checkbox" 
                    className="custom-control-input"
                    id={row.id}
                    checked={row.isVIP}
                    onChange={ () => action(row.id, !row.isVIP) }
                />
                <label className="custom-control-label" htmlFor={row.id} />
            </div>
        )
    }
}

export default cellSwitch