import { 
    REQUEST_REVIEWS_START,
    REQUEST_REVIEWS_END,
    DELETE_REVIEW,
} from './types.js';

const initialState = {
    data: [],
    isFetching: false,
}

export const reviewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_REVIEWS_START:
            return { ...state, isFetching: true }
        case REQUEST_REVIEWS_END:
            return { ...state, isFetching: false, data: action.payload }
        case DELETE_REVIEW:
            return { ...state, data: _deleteReview(state.data, action.payload) }
        default: return state
    }
}

const _deleteReview = (data, payload) => {
    const { docId } = payload
    const docIndex = data.findIndex(doc => doc.id === docId)
    if (docIndex === -1) return data
    const reviews = data[docIndex].Reviews
    const reviewIndex = reviews.findIndex(review => review.ID === payload.data.ID)
    if (reviewIndex === -1) return data
    const filterId = reviews[reviewIndex].ID
    const filteredReviws = reviews.filter( r => r.ID !== filterId)
    const newData = [ ...data ]
    newData[docIndex].Reviews = filteredReviws
    newData[docIndex].count = filteredReviws.length
    // add function to recalculate rating
    return newData
}