import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DropdownMultilevel from '../dropdown/DropdownMultilevel'


const DropdownMulti = ({ handler, fieldName }) => {

    const [selected, setSelected] = useState([])
    const handleSelection = (field,value) => {
        value.length === 0 ? handler(field, 'All') : handler(field, value)
        setSelected(value)
    }

    return(
        <div className="filter_common">
            <DropdownMultilevel info={{ field: fieldName }} value={selected} handler={handleSelection} />
        </div>
    )
    
}

DropdownMulti.propTypes = {
    fieldName: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired,
  }

export default DropdownMulti