import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({ fieldName, handler, title }) => {

    const checkboxHandler = event => {
        const value = event.target.checked ? true : null
        handler(fieldName, value)
    }

    return(
        <div className="filter_common">
            <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" 
                    id="defaultUnchecked" onChange={e => checkboxHandler(e)} />
                <label className="custom-control-label" htmlFor="defaultUnchecked">{title}</label>
            </div>
        </div>
    )

}

Checkbox.propTypes = {
    handler: PropTypes.func.isRequired,
    fieldName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default Checkbox