import { 
    USER_LOGGED_IN_WITH_ID, 
    USER_LOGGED_OUT,
    USER_LOGIN_CHECK_START,
    USER_LOGIN_CHECK_END,
} from './types.js';

const initialState = {
    userID: null,
    email: null,
    checkingUser: true,
}

const handlers = {
    [USER_LOGGED_IN_WITH_ID]: (state, { payload: { userID, email } }) => ({ userID, email, checkingUser: false }),
    [USER_LOGGED_OUT]: state => ({ email: null, userID: null, checkingUser: false}),
    [USER_LOGIN_CHECK_START]: state => ({ ...state, checkingUser: true }),
    [USER_LOGIN_CHECK_END]: state => ({ ...state, checkingUser: false}),
    DEFAULT: state => state
  }

export const userReducer = (state = initialState, action) => {
    const handler = handlers[action.type] || handlers.DEFAULT
    return handler(state, action);
}