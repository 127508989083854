import React from 'react'
import PropTypes from 'prop-types'

const TextArea = ({ info, handler, value }) => {

    const label = info.label ? <label>{info.label}</label> : null
    return(
        <div className="form-group">
            {label}
            <textarea className="form-control" rows={info.rows} 
            placeholder={info.placeholder} name={info.name}
            value={value}
            onChange={handler}
            />
        </div>
    )

}

export default TextArea

TextArea.propTypes = {
    info: PropTypes.object,
    handler: PropTypes.func.isRequired,
    value: PropTypes.any
}