import React, {useState, useEffect} from 'react';
import Switch from 'react-ios-switch';
import PropTypes from 'prop-types';
import TimeRangePicker from '../../pages/mobileForm/components/TimeRangePicker/TimeRangePicker';
import {capitalize} from '../../utils/utils';
import styles from './DayCellAdmin.module.css';

const DayCell = ({workDay, handler, data, disabled}) => {
  const [selected, setSelected] = useState(data);
  useEffect(() => {
    if (JSON.stringify(selected) !== JSON.stringify(data)) {
      handler(workDay, selected);
    }
  }, [selected, workDay, handler, data]);

  const {from, to, isClosed} = data;
  const is24H = data['24h'];

  return (
    <div className={styles.work_hours}>
      <div className="">
        <div className={styles.divis}>
          <div>
            <span>{capitalize(workDay)}: </span>
            <span style={{color: !isClosed ? 'green' : 'crimson'}}>
              {!isClosed ? 'Open' : 'Closed'}
            </span>
          </div>
          <Switch
            disabled={disabled}
            checked={!isClosed}
            onColor="#01B9E7"
            onChange={(flag) => setSelected({...selected, isClosed: !flag})}
          />
        </div>
        <div className={styles.divis}>
          <TimeRangePicker
            className="t-range-picker"
            from={from}
            till={to}
            disabled={disabled || isClosed || is24H}
            onChange={(time) => setSelected({...selected, from: time[0], to: time[1]})}
          />
          <label>Open 24H</label>
          <Switch
            className={styles.dcSwitch}
            checked={is24H}
            disabled={disabled || isClosed}
            onColor="#01B9E7"
            onChange={(flag) => {
              setSelected({...selected, '24h': flag});
            }}
          />
        </div>
      </div>
    </div>
  );
};

DayCell.propTypes = {
  workDay: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default DayCell;
