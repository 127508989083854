import firebase from 'firebase/app';
import {
  ADD_NEW_COMPANY,
  ADD_OFFER,
  CHANGE_COMPANY_VIPSTATUS,
  CHANGE_REQUEST_STATUS,
  DELETE_COMPANY,
  DELETE_OFFER,
  EDIT_COMPANY_INFO,
  ELEMENT_APPROVED,
  ELEMENT_TABLE_SELECT,
  FETCH_OFFERS,
  REQUEST_COMPANIES_END,
  REQUEST_COMPANIES_START,
  REQUEST_MORE_COMPANIES_END,
  UPDATE_OFFER,
} from './types.js';

const initialState = {
  data: [],
  lastVisible: null,
  isFetching: false,
  isAllDataLoaded: false,
  selectedID: null,
  error: null,
  requestFinished: false,
  offers: [],
};

export const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_COMPANIES_START:
      const data = action.payload && action.payload.data ? {data: action.payload.data} : {};
      return {...state, isFetching: true, lastVisible: null, isAllDataLoaded: false, ...data};

    case REQUEST_COMPANIES_END:
      return {...state, isFetching: false, ...action.payload};

    case REQUEST_MORE_COMPANIES_END:
      return {
        ...state,
        isFetching: false,
        data: [...state.data, ...action.payload.data],
        lastVisible: action.payload.lastVisible,
        isAllDataLoaded: action.payload.isAllDataLoaded,
      };

    case CHANGE_REQUEST_STATUS:
      return {...state, requestFinished: action.payload};
    case ELEMENT_TABLE_SELECT:
      return {...state, selectedID: action.payload};
    case ELEMENT_APPROVED:
      return {...state, data: approveElement(action.payload, state)};
    case ADD_NEW_COMPANY:
      return {...state, requestFinished: true, data: [_newCompany(action.payload), ...state.data]};
    case CHANGE_COMPANY_VIPSTATUS:
      const {id, newStatus} = action.payload;
      return {...state, isFetching: false, data: changeVipStatus(id, newStatus, state)};
    case EDIT_COMPANY_INFO:
      return {
        ...state,
        isFetching: false,
        requestFinished: true,
        data: editCompany(action.payload, state),
      };
    case DELETE_COMPANY:
      return {
        ...state,
        isFetching: false,
        requestFinished: true,
        data: deleteCompany(action.payload, state),
      };
    case FETCH_OFFERS:
      return {...state, isFetching: false, offers: action.payload};
    case ADD_OFFER:
      return {...state, offers: [action.payload, ...state.offers]};
    case DELETE_OFFER:
      return {...state, offers: [...state.offers.filter((item) => item.id !== action.payload.id)]};
    case UPDATE_OFFER:
      return {
        ...state,
        offers: [
          action.payload.newOffer,
          ...state.offers.filter((item) => item.id !== action.payload.offer.id),
        ],
      };
    default:
      return state;
  }
};

const findByID = (id, companies) => companies.findIndex((company) => company.id === id);

const approveElement = (id, {data}) => {
  const companies = [...data];
  companies[findByID(id, data)].status = 'approved';
  return companies;
};

const changeVipStatus = (id, newStatus, {data}) => {
  const companies = [...data];
  companies[findByID(id, data)].isVIP = newStatus;
  return companies;
};

const editCompany = (newData, {data}) => {
  const companies = [...data];
  companies[findByID(newData.id, data)] = {...newData, dateModified: _now()};
  return companies;
};

const deleteCompany = ({id}, {data}) => data.filter((company) => company.id !== id);

const _now = () => firebase.firestore.Timestamp.now();

const _newCompany = (data) => ({...data, dateAdded: _now(), dateModified: _now()});
