import React from 'react';
import './style.scss';
import {Loader} from "../loader/Loader";

export const LoaderOverlay = () => {
    return(
        <div className="loader-overlay fixed-top h-100 d-flex align-items-center justify-content-center">
            <Loader/>
        </div>
    );
}
