import React from 'react'
import PropTypes from 'prop-types'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import Modal from "react-bootstrap/Modal"
import "./style.css"

const ModalMap = ({ isOpen, hide, location, onSelect }) => {

    const center = location ? location : { lat: 40.071, lng: -101.020 }
    const dragMarker = e => onSelect({ lat: e.latLng.lat(), lng: e.latLng.lng()})

    return (
        <Modal show={isOpen} onHide={hide}>
          <Modal.Header>Select company location</Modal.Header>
          <Modal.Body>
              <div className="mapBody">
                <LoadScript
                    googleMapsApiKey="AIzaSyC0irlGmAWNoRy1Pakixa8ekcq9Ii0GSbU"
                >
                    <GoogleMap
                        mapContainerClassName="mapBody"
                        center={center}
                        zoom={4}
                        clickableIcons={false}
                        onClick={ dragMarker }
                    >
                        <Marker position={center} draggable onDragEnd={ dragMarker } />
                    </GoogleMap>
                </LoadScript>
              </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={() => hide()}>Close</button>
            <button type="button" className="btn btn-primary" onClick={() => hide()}>Save changes</button>
          </Modal.Footer>
        </Modal>
      );

}

ModalMap.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
    location: PropTypes.object,
    onSelect: PropTypes.func.isRequired,
  }


export default ModalMap
