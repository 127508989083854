import React from 'react'

const cellStringSwitch = (action) => {
    return (cell, row) => {
        return (
            <div className="custom-control custom-switch">
                <button type="button" className="btn btn-link btn-dec" htmlFor={row.id}
                    onClick={() => action(row.id, _getStatus(row.Status) )}>
                    {'Change'}
                </button>
                {row.Status}
            </div>
        )
    }
}

const _getStatus = status => {
    if (status === 'New') return 'Approved'
    return 'New'
}

export default cellStringSwitch