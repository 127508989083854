import React from 'react'
import { NavLink } from 'react-router-dom'
import './style.css'

const SelectedButton = ({ info }) => {

    return(
        <div className="cp_button">
            <NavLink to={info.to} strict >{info.title}</NavLink>
        </div>
    )
}

export default SelectedButton