import React from 'react'
import SelectorButton from './SelectorButton.js'
import './style.css'

const PageSelector = () => {

    const _pages = [{to: '/companies', title: 'Company'}, {to:'/reviews', title: 'Reviews'},
                    {to: '/edit_requests', title: 'Edit Requests'},
                    {to : '/add_company', title: 'Add Company'},
                    {to: '/offers', title: 'Offers'}, {to: '/import', title: 'Import'}]

    return (
        <div className="cp_main">
            {_pages.map( pageInfo => <SelectorButton info={pageInfo} key={pageInfo.to} />)}
        </div>
    )

}

export default PageSelector