import React from 'react'

const Checkbox = ({ info, handler, value }) => {

    const checkboxHandler = event => handler( info.name, event.target.checked )

    return(
        <div className="filter_common">
            <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" 
                    checked={value} id="defaultUnchecked" 
                    onChange={e => checkboxHandler(e)} 
                />
                <label className="custom-control-label" htmlFor="defaultUnchecked">{info.title}</label>
            </div>
        </div>
    )

}

export default Checkbox