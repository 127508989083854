import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import firebase from '../../firebase/firebaseApp.js';
import {Loader} from '../../components/loader/Loader.js';
import './Login.css';
import logo from '../../media/logo.png';

class Login extends React.Component {
  state = {
    email: '',
    password: '',
  };

  handleInputs = (event) => {
    this.setState({...this.state, [event.target.name]: event.target.value});
  };

  loginUser = async (event) => {
    event.preventDefault();
    const {email, password} = this.state;
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const {loggedIn, userCheck} = this.props;
    if (userCheck) return <Loader />;
    if (loggedIn) return <Redirect to="/companies" />;

    return (
      <div className="screen">
        <div className="main">
          <img src={logo} alt="Logo" />
          <h1 className="l-h">Login</h1>
          <form onSubmit={this.loginUser}>
            <div className="">
              <input
                type="email"
                className="form-control input"
                name="email"
                placeholder="Email"
                required={false}
                onChange={this.handleInputs}
              />
            </div>
            <div className="">
              <input
                type="password"
                className="form-control input"
                name="password"
                placeholder="Password"
                required={false}
                onChange={this.handleInputs}
              />
            </div>
            <button type="submit" className="btn btn-primary w-100 button">
              Login
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.user.userID,
  userCheck: state.user.checkingUser,
});

export default connect(mapStateToProps)(Login);

/* - Add admin
// import { addAdmin } from '../../firebase/addAdminRole.js';
//addAdmin({email: 'second@mail.ru'}).then(response => console.log(response)).catch(error => console.log(error));
*/
