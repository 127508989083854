import React from 'react'
import PropTypes from 'prop-types'
import { SegmentedControl } from 'segmented-control'

const Segment = ({ info, handler, value }) => {
    const mapOptions = options => options.map( opt => ({ ...opt, default: opt.value === value }))
    return(
        <div className="form-group">
            <label>{info.label}</label>
            <SegmentedControl 
                style={{ margin: 0 }}
                name={info.name}
                options={mapOptions(info.options)}
                setValue={ value => {
                    handler(info.name, value)
                } }
            />
        </div>
    )

}

Segment.propTypes = {
    info: PropTypes.object,
    handler: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
}

export default Segment