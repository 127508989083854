import React from 'react'

const LoaderProgress = ({companiesFetched, companiesImported, reviewsImported, isImportFinished}) => {
    const mainStat = `Companies imported: ${companiesFetched}   `
    const loadStat = `\nLoaded companies: ${companiesImported} \nReviews loaded: ${reviewsImported}`
    let info = `${mainStat}${loadStat}`

    if (isImportFinished && companiesFetched.length === 0) {
        info = 'No companies imported'
    }
    if (isImportFinished && companiesImported === 0) {
        info = `${mainStat}\nNo companies loaded`
    }

    return (
        <div className="form-group loadProgressWrap">
            <label htmlFor="Textarea">Import progress</label>
            <textarea readOnly className="form-control" id="Textarea" rows="6" value={info}/>
        </div>
    )
}

export default LoaderProgress
