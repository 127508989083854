import React, { useEffect }  from 'react'
import { Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import withNavBar from '../../hoc/withNavBar.js'
import { addNewCompany, changeRequestStatus } from '../../redux/companiesActions.js'
import CompanyForm from '../../components/companyForm/CompanyForm.js'

const NewCompany = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const requestEnded = useSelector( state => state.companies.requestFinished)

    useEffect(() => {
        if(requestEnded) { dispatch(changeRequestStatus(false)) }
      }, [requestEnded, dispatch])
      
    const handleSubmit = (event, company) => {
        event.preventDefault()
        switch (event.target.value) {
            case 'cancel': history.push('/'); break
            case 'save': checkAndAddCompany(company); break
            default: return
        }
    }

    const checkAndAddCompany = (company) => {
        if(company.address === ''){ alert('Address field is required'); return }
        const { length } = company.Services
        if(length === 0){ alert('Type of business field is required'); return }
        dispatch(addNewCompany(company))
    }

    if(requestEnded) return <Redirect to="/" />

    return (
        <div className="pageWrapper">
            <div>Add Company</div>
            <CompanyForm handler={handleSubmit}/>
        </div>
    )
}

export default withNavBar(NewCompany)