import {
    FETCH_REQUESTS,
    FETCH_REQUESTS_END,
    FETCH_REQUESTS_REFRESH,
    SWITCH_REQUEST_STATUS } from './types.js'
import { fetchRequests, changeStatus } from '../firebase/requestsApi'

const startFetchingRequests = () => ({ type: FETCH_REQUESTS })
const endFetchRequestData = data => ({ type: FETCH_REQUESTS_END, payload: data})
const changeStatusAction = (id, status) => ({ type: SWITCH_REQUEST_STATUS, payload: { id, status } })

export function getRequests(filters) {
    return async dispatch => {
        dispatch(startFetchingRequests())
        const requests = await fetchRequests(filters)
        dispatch(endFetchRequestData(requests))
    }
}

export function switchStatus(id, status) {
    return async dispatch => {
        await changeStatus(id, status)
        dispatch(changeStatusAction(id, status))
    }
}

export const refreshStatus = () => ({ type: FETCH_REQUESTS_REFRESH })

